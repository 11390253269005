import React, { lazy, useEffect } from "react";
import {
    Edit3,
    User,
    HelpCircle,
    LifeBuoy,
    Settings,
    LogOut,
} from "react-feather";
import {
    DropdownToggle,
    Dropdown
} from "../../ui/dropdown/dropdown";
import {
    Avatar,
    AvatarInitial,
} from "../../ui/avatar/avatar"
import {
    StyledDropMenu,
    StyledAuthorName,
    StyledAuthorRole,
    StyledDropItem,
    StyledDivider,
    StyledAvatar,
} from "./style";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { BroadcastChannel } from 'broadcast-channel';
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { getMyUserDetailList } from "../../../../redux/actions/memberAction";
import { StyledLink } from "../../ui/anchor/style";
import { Button } from "./../../../../components";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const useStylesButton = makeStyles((theme: Theme) =>
    createStyles({
      buttonList: {
        fontWeight: "bold",
        margin: "7px",
        position: "relative",
        float: "right",
      },
      buttonTransfer: {
        fontWeight: "bold",
        margin: "7px",
      },
      buttonSave: {
        margin: "7px",
        fontWeight: "bold",
        position: "relative",
        float: "right",
      },
      buttonIcon: {
        marginRight: "5px",
      },
      // buttonIconDelete: {
      //     position: 'absolute',
      //     //textAlign: 'center',
      //     top: "22px",
      //     color: 'danger',
      //     height: "25px",
      //     width: "25px",
      // },
      buttonIconDelete: {
        marginRight: "5px",
        color: "danger",
      },
      buttonIconUpdate: {
        position: "relative",
        textAlign: "center",
      },
      formControl: {
        width: "100%",
        color: "black",
        "& label.Mui-focused": {
          color: "#3b8c8c",
        },
        "& .MuiInput-underline:after": {
          borderBottomColor: "#3b8c8c",
        },
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            borderColor: "black",
          },
          "&:hover fieldset": {
            borderColor: "orange",
          },
          "&.Mui-focused fieldset": {
            borderColor: "#3b8c8c",
          },
        },
      },
      inputControl: {
        position: "relative",
        width: "100%",
        color: "black",
        height: "100%",
        borderRadius: "4px",
        borderColor: "#AEADAD",
        fontSize: "16px",
      },
      TextFieldControl: {
        overflowWrap: "break-word",
        backgroundColor: "rgb(255, 255, 255)",
        backgroundClip: "border-box",
        border: "1px solid transparent",
        borderRadius: "4px",
        boxShadow: "rgb(26 108 225 / 10%) 0px 0px 25px",
        padding: "15px 15px 15px 15px",
        height: "100%",
      },
      colorBackground: {
        backgroundColor: "rgb(247,250,255)",
      },
    })
  );

const Help = lazy(() => import("../../../../pages/help/Help"));
// "./pages/help/Help"

const logoutChannel = new BroadcastChannel('logout');

export const logOut = () => {
    logoutChannel.postMessage("Logout")
    localStorage.removeItem("token")
    window.location.href = window.location.origin + "/login";
}

export const logoutAllTabs = () => {
    logoutChannel.onmessage = () => {
        logOut();
        logoutChannel.close();

    }
}

const ProfileDropdown: React.FC = () => {
    const { myUserDetailList } = useSelector((state: RootStateOrAny) => state.myUserDetail);
    // const userId: number = myUserDetailList?.data?.id;
    const nameSurname: string = myUserDetailList?.data?.nameSurname;
    const dis = useDispatch();

    useEffect(() => {
        if (!myUserDetailList) {
            getMyUserDetails()
        }
    }, [])

    const getMyUserDetails = () => {
        dis(getMyUserDetailList({ state: { memberId: 0 } }));
    }

    // const logOut=()=>{
    //     localStorage.removeItem('token');    
    //   }
   
    const classesButton = useStylesButton();

    return (
        <Dropdown direction="down" className="dropdown-profile">
            <DropdownToggle variant="texted">
                <StyledAvatar size="sm">
                    <AvatarInitial>RDC</AvatarInitial>
                </StyledAvatar>
            </DropdownToggle>
            <StyledDropMenu>
                <StyledLink to={'/profile/0'}>
                    <Avatar size="lg">
                        <AvatarInitial>RDC</AvatarInitial>
                    </Avatar>
                    <StyledAuthorName style={{ textTransform: 'capitalize' }}>{nameSurname}</StyledAuthorName>
                    {
                        Object.values(myUserDetailList).length > 0 &&
                        Object.values(myUserDetailList).map((myUserItem: any) => (
                            myUserItem.DepartmentList && Object.values(myUserItem.DepartmentList).length > 0) &&
                            Object.values(myUserItem.DepartmentList).map((department: any, index) => (
                                <StyledAuthorRole key={index} > {department.Task}</StyledAuthorRole>
                            )))}
                </StyledLink>
                <StyledDropItem path="/profile/0">
                    <Edit3 size="24" />
                    Profili incele
                </StyledDropItem>
                <StyledDivider />
                <StyledDropItem
                    path="/help"
                    mt="10px"
                >
                    <HelpCircle size="24" />
                    Yardım
                </StyledDropItem>
                <StyledDropItem path="/profile" mt="10px">
                    <Settings size="24" />
                    Hesap ayarları
                </StyledDropItem>
                <StyledDropItem path="/profile" mt="10px">
                    <Settings size="24" />
                    Gizlilik ayarları
                </StyledDropItem>
                <StyledDropItem path="/login" mt="10px">
                    {/* <Button onClick ={logOut}
                    variant="contained"
                    color="default"
                    startIcon={<ExitToAppIcon />}
                >
                ÇIKIŞ YAP
                </Button> */}
                    <Button
                      iconButton
                      size="lg"
                      color="light"
                      className={classesButton.buttonList}
                     onClick={logOut} 
                     >
                        <ExitToAppIcon style={{ marginRight: '8px' }} />
                        ÇIKIŞ YAP
                    </Button>
                </StyledDropItem>
            </StyledDropMenu>
        </Dropdown>
    );
};

export default ProfileDropdown;
