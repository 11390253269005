import React, { useEffect, useState } from "react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  TextField,
  Select,
  MenuItem,
  TableHead,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Snackbar,
  Tooltip,
} from "@mui/material";
import logo from "../../../static/logo.png";
import { useLocation, useParams } from "react-router";
import axios from "axios";
import { SERVICEURL } from "../../../appconfig";
import { verifyHash } from "../../../utils/hash";
import queryString from "query-string";
import { StyledContainer } from "./style";

type EvaluationFormParams = {
  evaluationFormId: string;
};

type FormDetail = {
  EvaluationFormID: number;
  QuestionID: number;
  Answer: string;
  EvaluatorName?: string; // Opsiyonel olarak tanımlıyoruz
  EvaluatorPosition?: string; // Opsiyonel olarak tanımlıyoruz
};

const TrialPeriodForm = () => {
  const locationData = useLocation();
  const { isHR } = queryString.parse(locationData.search);
  const { evaluationFormId } = useParams<EvaluationFormParams>();
  const today = new Date().toLocaleDateString("tr-TR");
  const [evaluatorInfo, setEvaluatorInfo] = useState({
    name: "",
    position: "",
  });
  const [formData, setFormData] = useState<any>({});
  const [formQuestions, setFormQuestions] = useState<String[]>([]);
  const [answers, setAnswers] = useState<number[]>(
    Array(formQuestions.length).fill(0)
  );
  const [preferEmployee, setPreferEmployee] = useState("");
  const [explanation, setExplanation] = useState("");
  const [isSaved, setIsSaved] = useState(false);
  const [showUnsavedAlert, setShowUnsavedAlert] = useState(false);
  const [showConfirmSubmit, setShowConfirmSubmit] = useState(false);
  const [showExpErrorSnackbar, setShowExpErrorSnackbar] = useState(false);
  const [showEvaluatorError, setShowEvaluatorError] = useState(false);
  const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);
  const [showSendSuccessSnackbar, setShowSendSuccessSnackbar] = useState(false);
  const [showSendErrorSnackbar, setShowSendErrorSnackbar] = useState(false);
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
  const [possibleIDs, setPossibleIDs] = useState([]);
  const [matchingID, setMatchingID] = useState();
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState("");

  const mytoken = localStorage.getItem("token");
  const HEADER = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + mytoken,
    },
  };

  useEffect(() => {
    if (mytoken != null) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, [mytoken]);

  // Hash icin ID'leri al
  useEffect(() => {
    if (!evaluationFormId) return;

    axios
      .get(SERVICEURL + "api/Data/GetFormID", HEADER)
      .then((response) => {
        setPossibleIDs(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  }, [evaluationFormId]);

  // Dogrulama yap
  useEffect(() => {
    if (possibleIDs.length > 0) {
      const matchedID = possibleIDs.find((id) =>
        verifyHash(evaluationFormId, id)
      );
      setMatchingID(matchedID);
    }
  }, [possibleIDs, evaluationFormId]);

  // Form gecerli mi
  useEffect(() => {
    if (matchingID) {
      const input = {
        EvaluationFormID: matchingID,
      };

      axios
        .post(SERVICEURL + "api/Data/IsFormValid", input, HEADER)
        .then((response) => {
          setIsFormValid(response.data.IsFormValid);
          setErrorMessage(response.data.Message);
        })
        .catch((error) => {
          console.error("Error fetching data: ", error);
        });
    }
  }, [matchingID]);

  useEffect(() => {
    if (matchingID && (isFormValid || isLoggedIn)) {
      const input = {
        EvaluationFormID: matchingID,
      };

      axios
        .post(SERVICEURL + "api/Data/GetEvaluationFormData", input, HEADER)
        .then((response) => {
          setFormData(response.data);
        })
        .catch((error) => {
          console.error("Error fetching data: ", error);
        });
    }
  }, [matchingID,isFormValid,isLoggedIn]);

  // Form sorulari icin
  useEffect(() => {
    if (matchingID && (isFormValid || isLoggedIn)) {
      const input = {
        EvaluationFormID: matchingID,
      };

      axios
        .post(SERVICEURL + "api/Data/GetFormQuestions", input, HEADER)
        .then((response) => {
          setFormQuestions(response.data);
        })
        .catch((error) => {
          console.error("Error fetching data: ", error);
        });
    }
  }, [matchingID,isFormValid,isLoggedIn]);

  // Form cevaplari icin
  useEffect(() => {
    if (matchingID && isLoggedIn) {
      var input = { EvaluationFormId: matchingID };
      axios
        .post(SERVICEURL + "api/Data/GetEvaluationFormAnswers", input, HEADER)
        .then((response) => {
          if (response.data.length > 0) {
            const numericAnswers = response.data
              .slice(0, -3)
              .map((answer: string) => parseInt(answer, 10) || 0); // Eğer dönüşüm başarısızsa 0 ata

            setAnswers(numericAnswers);
            setPreferEmployee(response.data[13]);
            setExplanation(response.data[14]);

            // Değerlendirici bilgilerini ayarla
            const evaluatorName = response.data[response.data.length - 2]
              ?.split(":")[1]
              ?.trim();
            const evaluatorPosition = response.data[response.data.length - 1]
              ?.split(":")[1]
              ?.trim();
            setEvaluatorInfo({
              name: evaluatorName || "",
              position: evaluatorPosition || "",
            });
          }
        })
        .catch((error) => {
          console.error("Detaylar getirilemedi:", error);
        });
    }
  }, [matchingID]);

  const handlePointChange = (index: number, value: number): void => {
    const updatedAnswers = [...answers];
    updatedAnswers[index] = value;
    setAnswers(updatedAnswers);
    setIsSaved(false);
  };

  const calculateAverage = (startIndex: number, count: number): string => {
    const subset = answers
      .slice(startIndex, startIndex + count)
      .map((point) => (point !== undefined ? point : 0));
    const total = subset.reduce((sum, point) => sum + point, 0);
    return (total / count).toFixed(2);
  };

  const handleSave = () => {
    if (preferEmployee === "Hayır" && explanation.trim() === "") {
      setShowExpErrorSnackbar(true);
      return;
    }
    if (
      evaluatorInfo.name.trim() === "" ||
      evaluatorInfo.position.trim() === ""
    ) {
      setShowEvaluatorError(true);
      return;
    }
    if (answers.length !== formQuestions.length - 2) {
      setShowErrorSnackbar(true);
      return;
    }

    setIsSaved(true);
    setShowSuccessSnackbar(true);
  };

  const handleSend = () => {
    if (!isSaved) {
      setShowUnsavedAlert(true);
    } else {
      setShowConfirmSubmit(true);
    }
  };

  const confirmSubmit = () => {
    setShowConfirmSubmit(false);

    if (matchingID) {
      const formDetails: FormDetail[] = formQuestions
        .slice(0, -2) // Son soruyu çıkartıyoruz çünkü o serbest metin
        .map((question, index) => ({
          EvaluationFormID: parseInt(matchingID),
          QuestionID: index + 1,
          Answer: answers[index]?.toString() || "", // Eğer cevap yoksa boş string
        }));

      // Tercih sorusu
      formDetails.push({
        EvaluationFormID: parseInt(matchingID),
        QuestionID: formQuestions.length - 1,
        Answer: preferEmployee, // Çalışan tercih bilgisi
      });

      // Serbest metin cevabını ayrı olarak ekle
      if (preferEmployee === "Hayır"  && explanation.trim() !== "") {
        formDetails.push({
          EvaluationFormID: parseInt(matchingID),
          QuestionID: formQuestions.length,
          Answer: explanation.trim(), // Serbest metin cevabı
        });
      }

      // Değerlendirici bilgilerini ekliyoruz
      if (evaluatorInfo.name.trim() && evaluatorInfo.position.trim()) {
        formDetails.push({
          EvaluationFormID: parseInt(matchingID),
          EvaluatorName: evaluatorInfo.name.trim(),
          EvaluatorPosition: evaluatorInfo.position.trim(),
          QuestionID: formQuestions.length + 1,
          Answer: "",
        });
      }
      axios
        .post(
          SERVICEURL + "api/Data/SaveEvaluationFormDetails",
          formDetails,
          HEADER
        )
        .then((response) => {
          if (response.data.IsOk) {
            setShowSendSuccessSnackbar(true);
          } else {
            setShowSendErrorSnackbar(true);
          }
        })
        .catch((error) => {
          console.error("Hata oluştu:", error);
        });
    }
  };

  // Eğer form geçerli değilse ve giris yapilmadiysa hata mesajını göster
  if (!isLoggedIn && !isFormValid) {
    return (
      <div 
        style={{
        backgroundColor: "#f7faff",
        minHeight: "100vh",
        padding: "20px",
      }}
      >
        {/* Logo */}
        <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "20px",
        }}
        >
          <img src={logo} alt="RDC BOARD"></img>
        </Box>
        <Box>
        <Box>
          <Typography
            variant="h5"
            style={{ textAlign: "center", color: "black" }}
          >
            {errorMessage}
          </Typography>
        </Box>
      </Box>
      </div>      
    );
  }

  // Eğer form geçerli ise ya da giris yapildiysa sayfayi goster
  if (isFormValid || isLoggedIn) {
  return (
    <StyledContainer>
      {/* Logo */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "20px",
        }}
      >
        <img src={logo} alt="RDC BOARD"></img>
      </Box>
      <Box
        sx={{
          padding: "20px",
          borderRadius: "8px",
          boxShadow: "0 2px 10px rgba(0,0,0,0.1)",
          maxWidth: "1000px",
          margin: "20px auto",
          backgroundColor: "white",
        }}
      >
        <Typography
          variant="h5"
          sx={{ marginBottom: "20px", textAlign: "center", fontWeight: "bold" }}
        >
          {Array.isArray(formData) && formData.length > 0
            ? formData[0].EvaluationFormName
            : ""}
        </Typography>

        {/* Değerlendirme Tarihi */}
        <Typography variant="h6" sx={{ marginBottom: "20px" }}>
          Değerlendirme Tarihi: {today}
        </Typography>

        {/* Değerlendirilene Ait Bilgiler */}
        <Box sx={{ marginBottom: "20px" }}>
          <Typography
            variant="h6"
            sx={{
              marginBottom: "10px",
              textAlign: "center",
              fontWeight: "bold",
            }}
          >
            Değerlendirilene Ait Bilgiler
          </Typography>
          <TableContainer
            component={Paper}
            elevation={3}
            sx={{ marginBottom: "20px" }}
          >
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell
                    component="th"
                    scope="row"
                    style={{
                      fontWeight: "bold",
                      width: "40%",
                      borderRight: "1px solid #ccc",
                    }}
                  >
                    Adı Soyadı:
                  </TableCell>
                  <TableCell>
                    {Array.isArray(formData) && formData.length > 0
                      ? formData[0].MemberName
                      : ""}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    component="th"
                    scope="row"
                    style={{
                      fontWeight: "bold",
                      width: "40%",
                      borderRight: "1px solid #ccc",
                    }}
                  >
                    Görev Aldığı Birim:
                  </TableCell>
                  <TableCell>
                    {Array.isArray(formData) && formData.length > 0
                      ? formData[0].UnitName
                      : ""}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    component="th"
                    scope="row"
                    style={{
                      fontWeight: "bold",
                      width: "40%",
                      borderRight: "1px solid #ccc",
                    }}
                  >
                    İşe Giriş Tarihi:
                  </TableCell>
                  <TableCell>
                    {Array.isArray(formData) && formData.length > 0
                      ? formData[0].WorkStartDate
                      : ""}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    component="th"
                    scope="row"
                    style={{
                      fontWeight: "bold",
                      width: "40%",
                      borderRight: "1px solid #ccc",
                    }}
                  >
                    Pozisyonu:
                  </TableCell>
                  <TableCell>
                    {Array.isArray(formData) && formData.length > 0
                      ? formData[0].PositionName
                      : ""}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <br />
        {/* Değerlendirene Ait Bilgiler */}
        <Box sx={{ marginBottom: "20px" }}>
          <Typography
            variant="h6"
            sx={{
              marginBottom: "10px",
              textAlign: "center",
              fontWeight: "bold",
            }}
          >
            Değerlendirene Ait Bilgiler
          </Typography>
          <TableContainer
            component={Paper}
            elevation={3}
            sx={{ marginBottom: "20px" }}
          >
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell style={{ fontWeight: "bold", width: "38%" }}>
                    Adı Soyadı:
                  </TableCell>
                  <TableCell>
                    <TextField
                      fullWidth
                      required
                      value={evaluatorInfo.name}
                      onChange={(e) =>
                        setEvaluatorInfo({
                          ...evaluatorInfo,
                          name: e.target.value,
                        })
                      }
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ fontWeight: "bold" }}>
                    Pozisyonu:
                  </TableCell>
                  <TableCell>
                    <TextField
                      fullWidth
                      required
                      value={evaluatorInfo.position}
                      onChange={(e) =>
                        setEvaluatorInfo({
                          ...evaluatorInfo,
                          position: e.target.value,
                        })
                      }
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <br />

        {/* Değerlendirme Başlığı */}
        <Box sx={{ marginBottom: "20px" }}>
          <Typography
            variant="h6"
            sx={{
              marginBottom: "10px",
              textAlign: "center",
              fontWeight: "bold",
            }}
          >
            DEĞERLENDİRME
          </Typography>
        </Box>

        {/* Kurumsal Yetkinlikler */}
        <TableContainer
          component={Paper}
          elevation={3}
          sx={{ marginBottom: "20px" }}
        >
          <Table>
            <TableHead style={{ backgroundColor: "#e6ecfa" }}>
              <TableRow>
                <TableCell style={{ fontWeight: "bold", width: "10%" }}>
                  Ağırlık
                </TableCell>
                <TableCell style={{ fontWeight: "bold", width: "60%" }}>
                  Kurumsal Yetkinliklere Göre               
                </TableCell>
                <TableCell style={{ fontWeight: "bold", width: "30%" }}>
                  Puan
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {formQuestions.slice(0, 7).map((competency, index) => (
                <TableRow key={index}>
                  <TableCell>14.3%</TableCell>
                  <TableCell>{competency}</TableCell>
                  <TableCell>
                    <Select
                      value={answers[index] !== undefined ? answers[index] : 0} // undefined kontrolü yapıldı
                      onChange={(e) =>
                        handlePointChange(index, e.target.value as number)
                      }
                      displayEmpty
                      fullWidth
                      MenuProps={{
                        disableScrollLock: true,
                      }}
                    >
                      <MenuItem value={0} disabled>
                        Puan Seçin
                      </MenuItem>
                      <MenuItem value={5}>5 (Beklenenin Çok Üstünde)</MenuItem>
                      <MenuItem value={4}>4 (Beklenenin Üstünde)</MenuItem>
                      <MenuItem value={3}>3 (Bekleneni Karşılıyor)</MenuItem>
                      <MenuItem value={2}>2 (Beklenenin Altında)</MenuItem>
                      <MenuItem value={1}>1 (Beklenenin Çok Altında)</MenuItem>
                    </Select>
                  </TableCell>
                </TableRow>
              ))}
              <TableRow>
                  <TableCell
                    colSpan={2}
                    style={{ fontWeight: "bold", textAlign: "right", borderRight: "2px solid #ccc"}}
                  >
                    <Tooltip title="Her sorunun ağırlığı %14,3 olarak hesaplanmıştır.">
                    <span style={{cursor:"pointer"}}>Ara Toplam</span>
                    </Tooltip>
                  </TableCell>
                  <TableCell
                    style={{
                      fontWeight: "bold",
                      fontSize: "15px",
                      textAlign: "right",
                      borderRight: "2px solid #ccc"
                    }}
                  >
                    <Tooltip title="Her sorunun ağırlığı %14,3 olarak hesaplanmıştır.">
                    <span style={{cursor:"pointer"}}>{calculateAverage(0, 7)}</span>
                    </Tooltip>
                  </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <br />

        {/* Çalışan Bilinci ve Profesyonellik */}
        <TableContainer component={Paper} elevation={3}>
          <Table>
            <TableHead style={{ backgroundColor: "#e6ecfa" }}>
              <TableRow>
                <TableCell style={{ fontWeight: "bold", width: "10%" }}>
                  Ağırlık
                </TableCell>
                <TableCell style={{ fontWeight: "bold", width: "60%" }}>
                  Çalışma Bilinci ve Profesyonellik
                </TableCell>
                <TableCell style={{ fontWeight: "bold", width: "30%" }}>
                  Puan
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {formQuestions.slice(7, -2).map((criterion, index) => (
                <TableRow key={index}>
                  <TableCell>16.7%</TableCell>
                  <TableCell>{criterion}</TableCell>
                  <TableCell>
                    <Select
                      value={
                        answers[index + 7] !== undefined
                          ? answers[index + 7]
                          : 0
                      } // undefined kontrolü yapıldı
                      onChange={(e) =>
                        handlePointChange(index + 7, e.target.value as number)
                      }
                      displayEmpty
                      fullWidth
                      MenuProps={{
                        disableScrollLock: true,
                      }}
                    >
                      <MenuItem value={0} disabled>
                        Puan Seçin
                      </MenuItem>
                      <MenuItem value={5}>5 (Beklenenin Çok Üstünde)</MenuItem>
                      <MenuItem value={4}>4 (Beklenenin Üstünde)</MenuItem>
                      <MenuItem value={3}>3 (Bekleneni Karşılıyor)</MenuItem>
                      <MenuItem value={2}>2 (Beklenenin Altında)</MenuItem>
                      <MenuItem value={1}>1 (Beklenenin Çok Altında)</MenuItem>
                    </Select>
                  </TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell
                  colSpan={2}
                  style={{ fontWeight: "bold", textAlign: "right", borderRight: "2px solid #ccc"}}
                  >
                  <Tooltip title="Her sorunun ağırlığı %16,7 olarak hesaplanmıştır.">
                  <span style={{cursor:"pointer"}}>Ara Toplam</span>
                  </Tooltip>
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    textAlign: "right",
                  }}
                >
                  <Tooltip title="Her sorunun ağırlığı %16,7 olarak hesaplanmıştır.">
                  <span style={{cursor:"pointer"}}>{calculateAverage(7, 6)}</span>
                  </Tooltip>
                </TableCell>
              </TableRow>
              <TableRow style={{ backgroundColor: "#e6ecfa" }}>
                <TableCell
                  colSpan={2}
                  style={{ fontWeight: "bold", textAlign: "right", borderRight: "2px solid #ccc"}}
                  >
                  Genel Toplam
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    textAlign: "right",
                  }}
                >
                  {(
                    (parseFloat(calculateAverage(0, 6)) +
                      parseFloat(calculateAverage(7, 6))) /
                    2
                  ).toFixed(2)}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        {/* Tercih Sorusu */}
        <Box
          sx={{
            marginTop: "20px",
            padding: "20px",
            border: "1px solid #ccc",
            borderRadius: "8px",
          }}
        >
          <Typography variant="body1" sx={{ marginBottom: "10px" }}>
            Genel toplamı 3 ve üzeri olan çalışan başarılı kabul edilecektir.
            <br />
            Bu pozisyonu yeniden dolduracak olsanız yine bu kişiyi tercih eder
            misiniz?
          </Typography>
          <RadioGroup
            row
            value={preferEmployee}
            onChange={(e) => {
              setIsSaved(false);
              setPreferEmployee(e.target.value);
            }}
          >
            <FormControlLabel value="Evet" control={<Radio />} label="Evet" />
            <FormControlLabel value="Hayır" control={<Radio />} label="Hayır" />
          </RadioGroup>

          {/* Açıklama Alanı */}
          {preferEmployee === "Hayır" && (
            <TextField
              fullWidth
              multiline
              rows={3}
              required
              value={explanation}
              onChange={(e) => setExplanation(e.target.value)}
              placeholder="Açıklama giriniz..."
              sx={{ marginTop: "15px" }}
              error={preferEmployee === "Hayır" && explanation.trim() === ""}
              helperText={
                preferEmployee === "Hayır" && explanation.trim() === ""
                  ? "Bu alan zorunludur."
                  : ""
              }
            />
          )}
        </Box>

        {/* Kaydet ve Gönder Butonları */}
        {!isHR && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              gap: "10px",
              marginTop: "20px",
            }}
          >
            <Button
              variant="outlined"
              onClick={handleSave}
            >
              Kaydet
            </Button>
            <Button variant="contained" color="primary" onClick={handleSend}>
              Gönder
            </Button>
          </Box>
        )}
      </Box>
      {/* Kaydedilmemiş Uyarısı */}
      <Dialog
        open={showUnsavedAlert}
        onClose={() => setShowUnsavedAlert(false)}
        disableScrollLock
      >
        <DialogTitle>Uyarı</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Lütfen değişiklikleri kaydediniz.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowUnsavedAlert(false)}>Tamam</Button>
        </DialogActions>
      </Dialog>
      {/* Gönderme Onayı */}
      <Dialog
        open={showConfirmSubmit}
        onClose={() => setShowConfirmSubmit(false)}
        disableScrollLock
      >
        <DialogTitle>Onay</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Formu gönderdikten sonra üzerinde değişiklik yapamazsınız. Onaylıyor
            musunuz?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowConfirmSubmit(false)}>Hayır</Button>
          <Button onClick={confirmSubmit} color="primary">
            Evet
          </Button>
        </DialogActions>
      </Dialog>

      {/* Başarı Snackbar */}
      <Snackbar
        open={showSuccessSnackbar}
        autoHideDuration={2000}
        onClose={() => setShowSuccessSnackbar(false)}
        message="Değişiklikler kaydedildi."
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      />
      <Snackbar
        open={showSendSuccessSnackbar}
        autoHideDuration={2000}
        onClose={() => setShowSendSuccessSnackbar(false)}
        message="Başarıyla gönderildi."
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      />

      {/* Hata Snackbar */}
      <Snackbar
        open={showErrorSnackbar}
        autoHideDuration={2000}
        onClose={() => setShowErrorSnackbar(false)}
        message="Lütfen tüm soruları cevaplayınız."
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      />
      <Snackbar
        open={showExpErrorSnackbar}
        autoHideDuration={2000}
        onClose={() => setShowExpErrorSnackbar(false)}
        message="Lütfen açıklama giriniz."
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      />
      <Snackbar
        open={showEvaluatorError}
        autoHideDuration={2000}
        onClose={() => setShowEvaluatorError(false)}
        message="Lütfen bilgilerinizi giriniz."
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      />
      <Snackbar
        open={showSendErrorSnackbar}
        autoHideDuration={2000}
        onClose={() => setShowSendErrorSnackbar(false)}
        message="Form zaten mevcut."
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      />
    </StyledContainer>
  );
  }

  // Default Sayfa
  return (
    <div
      style={{
        backgroundColor: "#f7faff",
        minHeight: "100vh",
        padding: "20px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Typography variant="h5" style={{ color: "gray" }}>
        Yükleniyor...
      </Typography>
    </div>
  )
};

export default TrialPeriodForm;
