import styled from "../../../shared/styled";
import { themeGet } from "../../../shared/styled";
import { Accordion, Typography, Button } from "@material-ui/core";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";

export const StyledHeader = styled(({ darkModeActive, ...props }) => (
  <Typography {...props} />
))`
  && {
    font-family: "IBM Plex Sans", sans-serif;
    variant: "h3";
    margin-bottom: 5px;
    line-height: 1.25;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    color: ${({ darkModeActive }) =>
      darkModeActive ? "rgb(205, 212, 224)" : "#253858"};
  }
`;

export const StyledContainer = styled(({ darkModeActive, ...props }) => (
  <div {...props} />
))`
  && {
    padding: 20px;
    max-height: 100vh; 
    overflow-y: auto !important; 
    background-color: ${({ darkModeActive }) =>
      darkModeActive ? "#3a475f" : "#f7faff"};
`;



export const StyledText = styled(({ darkModeActive, ...props }) => (
  <Typography {...props} />
))`
    && {
      font-family: "IBM Plex Sans", sans-serif;
      font-weight: 500;
      font-size: 16px;
      color: ${({ darkModeActive }) =>
        darkModeActive ? "rgb(205, 212, 224)" : "#253858"};
      strong, b {
          color: ${({ darkModeActive }) =>
            darkModeActive ? "rgb(205, 212, 224)" : "#253858"};
        }

    `;

export const StyledAccordion = styled(({ darkModeActive, ...props }) => (
  <Accordion {...props} />
))`
  && {
    background-color: ${({ darkModeActive }) =>
      darkModeActive ? "#3a475f" : "#fff"};
    color: ${({ darkModeActive }) =>
      darkModeActive ? "rgb(205, 212, 224)" : "#253858"};
  }
`;

export const StyledTableCellHeader = styled(({ darkModeActive, ...props }) => (
  <TableCell {...props} />
))`
  && {
    background-color: ${({ darkModeActive }) =>
      darkModeActive ? "rgb(255, 145, 82)" : "rgba(242, 113, 39, 0.12)"};
  }
`;

export const StyledTableContainer = styled(({ darkModeActive, ...props }) => (
  <Table {...props} />
))`
  && {
    border: 1px solid rgb(119, 128, 145);
    background-color: ${({ darkModeActive }) =>
      darkModeActive ? "#3a475f" : "#fff"};
    color: ${({ darkModeActive }) =>
      darkModeActive ? "rgb(205, 212, 224)" : "#253858"};
  }
`;

export const StyleHeader = styled.div`
  position: relative;
  font-size: 20px;
  font-weight: 520;
  text-align: center;
  align: middle;
  color: #333333;
  border-bottom: 3px solid #ffd478;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  background-color: #fff3d8;
  font-family: -apple-system, BlinkMacSystemFont, "Inter UI", Roboto, sans-serif;
  padding: 16px;
`;
export const StyleInput = styled.div`
  width: 100%;
`;
export const StyleSelect = styled.div`
  width: 100%;
`;
export const StyleComponent = styled.div`
  background-color: #fdfdfd;
  width: 100%;
  height: auto;
  position: absolute;
`;
export const StyleSquare = styled.div`
  width: 85%;
  height: auto;
  background-color: #fffaf0;
  position: static;
  margin: 110px auto 40px auto;
  border: 3px solid #ffd478;
  border-radius: 15px;
  box-shadow: 0 0 10px #ffb619;
`;
export const StyleFormGroup = styled.div`
  padding: 10px 40px 10px 40px;
`;
export const DetailedSearch = styled.div`
  width: 90%;
  height: auto;
  background-color: #ececec;
  position: relative;
  margin: auto;
  padding: 80px 40px 10px 40px;
  border: 2px solid #ff9f31;
  border-radius: 10px;
  display: none;
`;
export const DetailedSearchHeader = styled.div`
  width: 100%;
  height: 60px;
  background-color: #ff9f31;
  border-radius: 8px 8px 0px 0px;
  text-align: center;
  font-size: 22px;
  color: #f9f9f9;
  position: absolute;
  top: 0%;
  left: 0%;
  line-height: 55px;
`;

export const StyledTheadTR = styled(({ darkModeActive, ...props }) => (
  <tr {...props} />
))`
    && {
      font-size: 16px;
      border: 1px solid #a8a8a8;
      border-top-width: 0;
      background-color: ${({ darkModeActive }) =>
        darkModeActive ? "rgb(255, 145, 82)" : "rgba(242, 113, 39, 0.12)"};
      color: ${({ darkModeActive }) => (darkModeActive ? "#fff" : "#000")};
    }
    th {
        color: ${({ darkModeActive }) =>
          darkModeActive ? "#fff" : "#000"}; /* Set color of th */
      }
    th:first-child {
        border-left-width: 0;
        text-align: left;
      }
      th:last-child {
        border-right-width: 0;
      }
      &:last-child {
        th {
          font-size: 14px;
          letter-spacing: 0, 8px;
          padding: 10px 16px;
          font-weight: 500;
          &:not(:first-child) {
            text-align: center;
          }
        }
  `;

export const StyledTable = styled(({ darkModeActive, ...props }) => (
  <Table {...props} />
))`
      && {
        font-size: 16px;
        border: 1px solid #a8a8a8;
        border-top-width: 0;
        background-color: ${({ darkModeActive }) =>
          darkModeActive ? "#3a475f" : "#fff"};
        color: ${({ darkModeActive }) => (darkModeActive ? "#fff" : "#000")};
      }
      th:first-child {
          border-left-width: 0;
          text-align: left;
        }
        th:last-child {
          border-right-width: 0;
        }
        &:last-child {
          th {
            font-size: 14px;
            letter-spacing: 0, 8px;
            padding: 10px 16px;
            font-weight: 500;
            &:not(:first-child) {
              text-align: center;
            }
          }
    `;

export const StyledTD = styled.td`
  border-color: ${themeGet("colors.border")};
  font-size: 13px;
  position: relative;
  align-items: center !important;
  text-align: center;
  margin: 0px auto;
  vertical-align: middle !important;
  white-space: nowrap;
  &:first-child {
    text-align: left;
  }
  strong {
    font-weight: 500;
    font-family: ${themeGet("fonts.interUi")};
    color: #fff;
  }
`;

export const Container = styled.div`
  margin: 0px 120px 5px;
`;

export const StyledButton = styled(({ darkModeActive, ...props }) => (
  <Button {...props} />
))`
  && {
    margin-top: 15px;
    background-color: ${({ darkModeActive }) =>
      darkModeActive ? "rgb(40, 49, 67)" : "#e5e9f2"};
    color: ${({ darkModeActive }) => (darkModeActive ? "#fff" : "#1b2e4b")};
    transition: background-color 0.1s, color 0.1s;

    &:hover,
    &:focus {
      background-color: ${({ darkModeActive }) =>
        darkModeActive ? "rgb(40, 49, 67)" : "#c3cce1"};
    }
  }
`;
