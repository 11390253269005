
export const GET_MEMBER_SUCCESS = "GET_MEMBER_SUCCESS"
export const GET_MEMBER_ERROR = "GET_MEMBER_ERROR";
export const GET_MEMBER_ALL_LİST_SUCCESS = "GET_MEMBER_ALL_LİST_SUCCESS";
export const GET_MEMBER_ALL_LİST_ERROR = "GET_MEMBER_ALL_LİST_ERROR";
export const GET_MY_USER_DETAIL_SUCCESS = 'GET_MY_USER_DETAIL_SUCCESS';
export const GET_SEARCHED_PERSONEL_DETAIL_SUCCESS = 'GET_SEARCHED_PERSONEL_DETAIL_SUCCESS';
export const GET_MY_USER_DETAIL_ERROR = "GET_MY_USER_DETAIL_ERROR";
export const GET_ALL_PERSONNEL_LİST_SUCCESS = "GET_ALL_MEMBER_LİST_SUCCESS";
export const GET_ALL_PERSONNEL_LİST_ERROR = "GET_ALL_MEMBER_LİST_ERROR";
export const GET_ALL_PERFORMANCE_CRİTERİA_SUCCESS = "GET_ALL_PERFORMANCE_CRİTERİA_SUCCESS";
export const GET_ALL_PERFORMANCE_CRİTERİA_ERROR = "GET_ALL_PERFORMANCE_CRİTERİA_ERROR";
export const GET_COMPANIES_SUCCESS = "GET_COMPANIES_SUCCESS";
export const GET_COMPANIES_ERROR = "GET_COMPANIES_ERROR";
export const GET_CUSTOMER_LİST_BY_COMPANYID_SUCCESS = "GET_CUSTOMER_LİST_BY_COMPANYID_SUCCESS";
export const GET_CUSTOMER_LİST_BY_COMPANYID_ERROR = "GET_CUSTOMER_LİST_BY_COMPANYID_ERROR";
export const GET_TEAM_LİST_BY_CUSTOMER_SUCCESS = "GET_TEAM_LİST_BY_CUSTOMER_SUCCESS";
export const GET_TEAM_LİST_BY_CUSTOMER_ERROR = "GET_TEAM_LİST_BY_CUSTOMER_ERROR";
export const GET_MANAGER_BY_CUSTOMERID_SUCCESS = "GET_MANAGER_BY_CUSTOMERID_SUCCESS";
export const GET_MANAGER_BY_CUSTOMERID_ERROR = "GET_MANAGER_BY_CUSTOMERID_ERROR";
export const GET_EMPLOYEE_BY_INPUT_SUCCESS = "GET_EMPLOYEE_BY_INPUT";
export const GET_EMPLOYEE_BY_INPUT_ERROR = "GET_EMPLOYEE_BY_INPUT_ERROR";
export const GET_LİST_PERFORMANCE_EVULATİON_SUCCESS = "GET_LİST_PERFORMANCE_EVULATİON_SUCCESS";
export const GET_LİST_PERFORMANCE_EVULATİON_ERROR = "GET_LİST_PERFORMANCE_EVULATİON_ERROR";
export const GET_PERFORMANCE_DETAİL_SUCCESS = "GET_PERFORMANCE_DETAİL_SUCCESS";
export const GET_PERFORMANCE_DETAİL_ERROR = "GET_PERFORMANCE_DETAİL_ERROR";
export const GET_BIRTHDAY_LIST_SUCCESS = "GET_BIRTHDAY_LIST_SUCCESS";
export const GET_BIRTHDAY_LIST_ERROR = "GET_BIRTHDAY_LIST_ERROR";
export const GET_ALL_CANDIDATE_LİST_SUCCESS = "GET_ALL_CANDIDATE_LİST_SUCCESS";
export const GET_ALL_CANDIDATE_LİST_ERROR = "GET_ALL_CANDIDATE_LİST_ERROR";
export const GET_ALL_TECHNICAL_GROUP_SUCCESS = "GET_ALL_TECHNICAL_GROUP_SUCCESS";
export const GET_ALL_TECHNICAL_GROUP_ERROR = "GET_ALL_TECHNICAL_GROUP_ERROR";
export const GET_TECHNICAL_GROUP_SALARY_EXPECTATION_SUCCESS = "GET_TECHNICAL_GROUP_SALARY_EXPECTATION_SUCCESS";
export const GET_TECHNICAL_GROUP_SALARY_EXPECTATION_ERROR = "GET_TECHNICAL_GROUP_SALARY_EXPECTATION_ERROR";
export const GET_YEARS_OF_EXPERIENCE_SALARY_EXPECTATION_SUCCESS = "GET_YEARS_OF_EXPERIENCE_SALARY_EXPECTATION_SUCCESS";
export const GET_YEARS_OF_EXPERIENCE_SALARY_EXPECTATION_ERROR = "GET_YEARS_OF_EXPERIENCE_SALARY_EXPECTATION_ERROR";
export const GET_ALL_TREND_OVER_TIME_SUCCESS = "GET_ALL_TREND_OVER_TIME_SUCCESS";
export const GET_ALL_TREND_OVER_TIME_ERROR = "GET_ALL_TREND_OVER_TIME_ERROR";
export const GET_COLLAGE_LİST_SUCCESS = "GET_COLLAGE_LİST_SUCCESS";
export const GET_COLLAGE_LİST_ERROR = "GET_COLLAGE_LİST_ERROR";
export const GET_COLLAGE_DEPARTMENT_LİST_SUCCESS = "GET_COLLAGE_DEPARTMENT_LİST_SUCCESS";
export const GET_COLLAGE_DEPARTMENT_LİST_ERROR = "GET_COLLAGE_DEPARTMENT_LİST_ERROR";
export const GET_LANGUAGE_LİST_SUCCESS = "GET_LANGUAGE_LİST_SUCCESS";
export const GET_LANGUAGE_LİST_ERROR = "GET_LANGUAGE_LİST_ERROR";
export const GET_LANGUAGE_LEVEL_LİST_SUCCESS = "GET_LANGUAGE_LEVEL_LİST_SUCCESS";
export const GET_LANGUAGE_LEVEL_LİST_ERROR = "GET_LANGUAGE_LEVEL_LİST_ERROR";
export const GET_MEMBER_PROJECT_LİST_SUCCESS = "GET_MEMBER_PROJECT_LİST_SUCCESS";
export const GET_MEMBER_PROJECT_LİST_ERROR = "GET_MEMBER_PROJECT_LİST_ERROR";
export const GET_MEMBER_COURSE_LİST_SUCCESS = "GET_MEMBER_COURSE_LİST_SUCCESS";
export const GET_MEMBER_COURSE_LİST_ERROR = "GET_MEMBER_COURSE_LİST_ERROR";
export const GET_MEMBER_TECHNİC_LİST_SUCCESS = "GET_MEMBER_TECHNİC_LİST_SUCCESS";
export const GET_MEMBER_TECHNİC_LİST_ERROR = "GET_MEMBER_TECHNİC_LİST_ERROR";
export const GET_PERSONAL_ABİLİTY_LİST_SUCCESS = "GET_PERSONAL_ABİLİTY_LİST_SUCCESS";
export const GET_PERSONAL_ABİLİTY_LİST_ERROR = "GET_PERSONAL_ABİLİTY_LİST_ERROR";
export const GET_MEMBER_GOOD_PERSONAL_ABİLİTY_LİST_SUCCESS = "GET_MEMBER_GOOD_PERSONAL_ABİLİTY_LİST_SUCCESS";
export const GET_MEMBER_GOOD_PERSONAL_ABİLİTY_LİST_ERROR = "GET_MEMBER_GOOD_PERSONAL_ABİLİTY_LİST_ERROR";
export const GET_MEMBER_BAD_PERSONAL_ABİLİTY_LİST_SUCCESS = "GET_MEMBER_BAD_PERSONAL_ABİLİTY_LİST_SUCCESS";
export const GET_MEMBER_BAD_PERSONAL_ABİLİTY_LİST_ERROR = "GET_MEMBER_BAD_PERSONAL_ABİLİTY_LİST_ERROR";
export const GET_MEMBER_COLLAGE_LİST_SUCCESS = "GET_MEMBER_COLLAGE_LİST_SUCCESS";
export const GET_MEMBER_COLLAGE_LİST_ERROR = "GET_MEMBER_COLLAGE_LİST_ERROR";
export const GET_MEMBER_LANGUAGE_LİST_SUCCESS = "GET_MEMBER_LANGUAGE_LİST_SUCCESS";
export const GET_MEMBER_LANGUAGE_LİST_ERROR = "GET_MEMBER_LANGUAGE_LİST_ERROR";
export const GET_POSITION_LIST_SUCCESS = "GET_POSITION_LIST_SUCCESS";
export const GET_POSITION_LIST_ERROR = "GET_POSITION_LIST_ERROR";
export const GET_POSITION_LIST_BY_COMPANY_SUCCESS = "GET_POSITION_LIST_BY_COMPANY_SUCCESS";
export const GET_POSITION_LIST_BY_COMPANY_ERROR = "GET_POSITION_LIST_BY_COMPANY_ERROR";
export const GET_POSITION_LIST_BY_PROJECT_SUCCESS = "GET_POSITION_LIST_BY_PROJECT_SUCCESS";
export const GET_POSITION_LIST_BY_PROJECT_ERROR = "GET_POSITION_LIST_BY_PROJECT_ERROR";
export const GET_ALL_CUSTOMER_SUCCESS = "GET_ALL_CUSTOMER_SUCCESS";
export const GET_ALL_CUSTOMER_ERROR = "GET_ALL_CUSTOMER_ERROR";
export const GET_MEMBER_DOMAİN_LİST_SUCCESS = "GET_MEMBER_DOMAİN_LİST_SUCCESS";
export const GET_MEMBER_DOMAİN_LİST_ERROR = "GET_MEMBER_DOMAİN_LİST_ERROR";
export const GET_ALL_SUB_STATUS_LİST_SUCCESS = "GET_ALL_SUB_STATUS_LİST_SUCCESS";
export const GET_ALL_SUB_STATUS_LİST_ERROR = "GET_ALL_SUB_STATUS_LİST_ERROR";
export const GET_ALL_INTERVIWER_LİST_SUCCESS = "GET_ALL_SUB_STATUS_LİST_SUCCESS";
export const GET_ALL_INTERVIWER_LİST_ERROR = "GET_ALL_SUB_STATUS_LİST_ERROR";
export const GET_MEMBER_TECHNİCAL_DETAİL_SUCCESS = "GET_MEMBER_TECHNİCAL_DETAİL_SUCCESS";
export const GET_MEMBER_TECHNİCAL_DETAİL_ERROR = "GET_MEMBER_TECHNİCAL_DETAİL_ERROR";
export const GET_POSITION_LIST_BY_TECHNIC_GROUP_SUCCESS = "GET_POSITION_LIST_BY_TECHNIC_GROUP_SUCCESS";
export const GET_POSITION_LIST_BY_TECHNIC_GROUP_ERROR = "GET_POSITION_LIST_BY_TECHNIC_GROUP_ERROR";
export const GET_TECHNIC_GROUP_NUMBER_OF_PEOPLE_SUCCESS = "GET_TECHNIC_GROUP_NUMBER_OF_PEOPLE_SUCCESS";
export const GET_TECHNIC_GROUP_NUMBER_OF_PEOPLE_ERROR = "GET_TECHNIC_GROUP_NUMBER_OF_PEOPLE_ERROR";
export const GET_TECHNIC_GROUP_REPORT_SUCCESS = "GET_TECHNIC_GROUP_REPORT_SUCCESS";
export const GET_TECHNIC_GROUP_REPORT_ERROR = "GET_TECHNIC_GROUP_REPORT_ERROR";
export const GET_TECHNICICAL_SELECT_TREE_LIST_SUCCESS = "GET_TECHNICICAL_SELECT_TREE_LIST_SUCCESS";
export const GET_TECHNICICAL_SELECT_TREE_LIST_ERROR = "GET_TECHNICICAL_SELECT_TREE_LIST_ERROR";
export const GET_MAIN_TECHNOLOGIES_LIST_SUCCESS = "GET_MAIN_TECHNOLOGIES_LIST_SUCCESS";
export const GET_MAIN_TECHNOLOGIES_LIST_ERROR = "GET_MAIN_TECHNOLOGIES_LIST_ERROR";
export const GET_SUB_TECHNOLOGIES_LIST_SUCCESS = "GET_SUB_TECHNOLOGIES_LIST_SUCCESS";
export const GET_SUB_TECHNOLOGIES_LIST_ERROR = "GET_SUB_TECHNOLOGIES_LIST_ERROR";
export const GET_DOMAİN_LİST_SUCCESS = "GET_DOMAİN_LİST_SUCCESS";
export const GET_DOMAİN_LİST_ERROR = "GET_DOMAİN_LİST_ERROR";
export const GET_PERSONAL_COMMUNİCATİON_LEVEL_LİST_SUCCESS = "GET_PERSONAL_COMMUNİCATİON_LEVEL_LİST_SUCCESS";
export const GET_PERSONAL_COMMUNİCATİON_LEVEL_LİST_ERROR = "GET_PERSONAL_COMMUNİCATİON_LEVEL_LİST_ERROR";
export const GET_MEMBER_PERSONAL_SKİLL_LİST_SUCCESS = "GET_MEMBER_PERSONAL_SKİLL_LİST_SUCCESS";
export const GET_MEMBER_PERSONAL_SKİLL_LİST_ERROR = "GET_MEMBER_PERSONAL_SKİLL_LİST_ERROR";
export const GET_SUB_TECHNOLOGIES_LİST_BY_MAİN_GROUP_SUCCESS = "GET_SUB_TECHNOLOGIES_LİST_BY_MAİN_GROUP_SUCCESS";
export const GET_SUB_TECHNOLOGIES_LİST_BY_MAİN_GROUP_ERROR = "GET_SUB_TECHNOLOGIES_LİST_BY_MAİN_GROUP_ERROR";
export const GET_TECHNİC_GROUP_MAİN_LİST_SUCCESS = "GET_TECHNİC_GROUP_MAİN_LİST_SUCCESS";
export const GET_TECHNİC_GROUP_MAİN_LİST_ERROR = "GET_TECHNİC_GROUP_MAİN_LİST_ERROR";
export const GET_MEMBER_TECHNİC_GROUP_LİST_SUCCESS = "GET_MEMBER_TECHNİC_GROUP_LİST_SUCCESS";
export const GET_MEMBER_TECHNİC_GROUP_LİST_ERROR = "GET_MEMBER_TECHNİC_GROUP_LİST_ERROR";
export const GET_STATUS_STATİSTİC_LİST_SUCCESS = "GET_STATUS_STATİSTİC_LİST_SUCCESS";
export const GET_STATUS_STATİSTİC_LİST_ERROR = "GET_STATUS_STATİSTİC_LİST_ERROR";
export const GET_STATUS_STATİSTİC_BY_MEMBER_LİST_SUCCESS = "GET_STATUS_STATİSTİC_BY_MEMBER_LİST_SUCCESS";
export const GET_STATUS_STATİSTİC_BY_MEMBER_LİST_ERROR = "GET_STATUS_STATİSTİC_BY_MEMBER_LİST_ERROR";
export const GET_ALL_MEMBER_LİST_FOR_POSİTİON_SUCCESS = "GET_ALL_MEMBER_LİST_FOR_POSİTİON_SUCCESS";
export const GET_ALL_MEMBER_LİST_FOR_POSİTİON_ERROR = "GET_ALL_MEMBER_LİST_FOR_POSİTİON_ERROR";
export const GET_DOMAİN_MAİN_GROUP_LİST_SUCCESS = "GET_DOMAİN_MAİN_GROUP_LİST_SUCCESS";
export const GET_DOMAİN_MAİN_GROUP_LİST_ERROR = "GET_DOMAİN_MAİN_GROUP_LİST_ERROR";
export const GET_DOMAİN_SUB_GROUP_LİST_SUCCESS = "GET_DOMAİN_SUB_GROUP_LİST_SUCCESS";
export const GET_DOMAİN_SUB_GROUP_LİST_ERROR = "GET_DOMAİN_SUB_GROUP_LİST_ERROR";

export interface Member {
    id: number;
    userName: string;
}

export interface AllMember {
    id: number;
    userName: string;
}

export interface UserDetail {
    id: number;
    //userName: string;
}
export interface PersonelDetail {
    id: number;
    //userName: string;
}
export interface AllPersonnel {
    name: string;
    phoneNo: string;
    email: string;
}
export interface AllCriteria {

}
export interface Companies {
    id: number;
}
export interface Customer {
    companyId: number;
}

export interface Team {
    teamId: number;
}
export interface Manager {
    customerId: number;
    managerId: number;
}
export interface Employee {
    teamId: number;
    customerId: number;
    employeeId: number;
}
export interface Performance {
    companyId: number;
    teamId: number;
    customerId: number;
    employeeId: number;
    managerId: number;
    period: Date;
}
export interface PerformanceDetail {
    id: string;
}
export interface BirthdayList {
}

export interface CandidateList {
}
export interface TechnicalList {
}
export interface TechnicalGroupSalaryExpectationList {
    TechGroupId: number;
    PeriodStartDate: Date;
    PeriodEndDate: Date;
}
export interface YearsOfExperienceSalaryExpectationList {
}
export interface ByTrendOverTimeList {
}


export interface CollageList {
}

export interface CollageDepartmentList {
}

export interface MemberProjectList {
    memberId: number;
}

export interface MemberCourseList {
    memberId: number;
}

export interface MemberTechnicList {
    memberId: number;
}

export interface PersonalAbilityList {
}

export interface MemberGoodPersonalAbilityList {
    memberId: number;
}

export interface MemberBadPersonalAbilityList {
    memberId: number;
}

export interface MemberCollageList {
    memberId: number;
}
export interface MemberLanguageList {
    memberId: number;
}
export interface PositionList {
    technicalId: number;
    priority: number;
    workingModelId: number;
    projectTypeId: number;
}
export interface PositionListByCompany {
    companyName: string;
}
export interface PositionListByProject {
    projectId: number;
}
export interface CustomerList {
}
export interface MemberDomainList {
    memberId: number;
}
//
export interface MemberLanguageList {
    memberId: number;
}
export interface MemberLanguageLevelList {
    memberId: number;
}
export interface AllSubStatusList {

}
export interface InterviwerList {

}
export interface MemberTechnicDetailList {
    memberId: number;
}
export interface PositionListTechnicGroup {

}
export interface TechnicGroupNumberOfPeopleList {

}
export interface TechnicGroupReportList {

}
export interface TechnicalSelectTreeList {
}
export interface MainTechnologiesList {
}
export interface SubTechnologiesList {
}
export interface DomainList {
}
//
export interface LanguageList {
}
export interface LanguageLevelList {
}
export interface PersonalCommunicationLevelList {
}
export interface MemberPersonalSkillList {
}
export interface SubTechnologiesListbyMainGroup {
}
export interface TechnicGroupMainList {
}
export interface MemberTechnicGroupList {
}
export interface StatusStatisticList {
}
export interface StatusStatisticbyMemberList {
}
export interface AllMemberListForPosition {
}
export interface DomainMainGroupList {
}
export interface DomainSubGroupList {
}


interface getMemberListSuccess {

    type: typeof GET_MEMBER_SUCCESS,
    payload: Member[]
}

interface getMemberListError {
    type: typeof GET_MEMBER_ERROR,
    payload: string
}

interface getMemberAllListSuccess {
    type: typeof GET_MEMBER_ALL_LİST_SUCCESS,
    payload: AllMember[]
}

interface getMemberAllLisrtError {
    type: typeof GET_MEMBER_ALL_LİST_ERROR,
    payload: string
}

interface getMyUserDetailListSuccess {
    type: typeof GET_MY_USER_DETAIL_SUCCESS,
    payload: UserDetail[]
}
interface getSearchedPersonelDetailListSuccess {
    type: typeof GET_SEARCHED_PERSONEL_DETAIL_SUCCESS,
    payload: PersonelDetail[]
}

interface getMyUserDetailListError {
    type: typeof GET_MY_USER_DETAIL_ERROR,
    payload: string
}

interface getAllPersonnelListSuccess {
    type: typeof GET_ALL_PERSONNEL_LİST_SUCCESS,
    payload: AllPersonnel[]
}

interface getAllPersonnelListError {
    type: typeof GET_ALL_PERSONNEL_LİST_ERROR,
    payload: string
}
interface getAllPerformanceCriteriaSuccess {
    type: typeof GET_ALL_PERFORMANCE_CRİTERİA_SUCCESS,
    payload: AllCriteria[]
}

interface getAllPerformanceCriteriaError {
    type: typeof GET_ALL_PERFORMANCE_CRİTERİA_ERROR,
    payload: string
}
interface getComponiesSuccess {
    type: typeof GET_COMPANIES_SUCCESS,
    payload: Companies[]
}

interface getComponiesError {
    type: typeof GET_COMPANIES_ERROR,
    payload: string
}
interface getCustomerListByCompanyIdSuccess {
    type: typeof GET_CUSTOMER_LİST_BY_COMPANYID_SUCCESS,
    payload: Customer[]
}

interface getCustomerListByCompanyIdError {
    type: typeof GET_CUSTOMER_LİST_BY_COMPANYID_ERROR,
    payload: string
}
interface getTeamListByCustomerSuccess {
    type: typeof GET_TEAM_LİST_BY_CUSTOMER_SUCCESS,
    payload: Team[]
}
interface getTeamListByCustomerError {
    type: typeof GET_TEAM_LİST_BY_CUSTOMER_ERROR,
    payload: string
}
interface getManagerCustomerIdSuccess {
    type: typeof GET_MANAGER_BY_CUSTOMERID_SUCCESS,
    payload: Manager[]
}

interface getManagerCustomerIdError {
    type: typeof GET_MANAGER_BY_CUSTOMERID_ERROR,
    payload: string
}
interface getEmployeeByIdSuccess {
    type: typeof GET_EMPLOYEE_BY_INPUT_SUCCESS,
    payload: Employee[]
}

interface getEmployeeByIdError {
    type: typeof GET_EMPLOYEE_BY_INPUT_ERROR,
    payload: string
}
interface getListPerformanceEvulationSuccess {
    type: typeof GET_LİST_PERFORMANCE_EVULATİON_SUCCESS,
    payload: Performance[]
}

interface getListPerformanceEvulationError {
    type: typeof GET_LİST_PERFORMANCE_EVULATİON_ERROR,
    payload: string
}
interface getPerformaceDetailSuccess {
    type: typeof GET_PERFORMANCE_DETAİL_SUCCESS,
    payload: PerformanceDetail[]
}

interface getPerformanceDetailError {
    type: typeof GET_PERFORMANCE_DETAİL_ERROR,
    payload: string
}

interface getBirthdaySuccess {
    type: typeof GET_BIRTHDAY_LIST_SUCCESS,
    payload: BirthdayList[]
}

interface getBirthdayError {
    type: typeof GET_BIRTHDAY_LIST_ERROR,
    payload: string
}

interface getCandidateListSuccess {
    type: typeof GET_ALL_CANDIDATE_LİST_SUCCESS,
    payload: CandidateList[]
}

interface getCandidateListError {
    type: typeof GET_ALL_CANDIDATE_LİST_ERROR,
    payload: string
}

interface getTechnicalGroupListSuccess {
    type: typeof GET_ALL_TECHNICAL_GROUP_SUCCESS,
    payload: TechnicalList[]
}

interface getTechnicalGroupListError {
    type: typeof GET_ALL_TECHNICAL_GROUP_ERROR,
    payload: string
}

interface getTechnicalGroupSalaryExpectationSuccess {
    type: typeof GET_TECHNICAL_GROUP_SALARY_EXPECTATION_SUCCESS,
    payload: TechnicalGroupSalaryExpectationList[]
}

interface getTechnicalGroupSalaryExpectationError {
    type: typeof GET_TECHNICAL_GROUP_SALARY_EXPECTATION_ERROR,
    payload: string
}

interface getYearsOfExperienceSalaryExpectationSuccess {
    type: typeof GET_YEARS_OF_EXPERIENCE_SALARY_EXPECTATION_SUCCESS,
    payload: YearsOfExperienceSalaryExpectationList[]
}

interface getYearsOfExperienceSalaryExpectationError {
    type: typeof GET_YEARS_OF_EXPERIENCE_SALARY_EXPECTATION_ERROR,
    payload: string
}

interface getByTrendOverTimeSuccess {
    type: typeof GET_ALL_TREND_OVER_TIME_SUCCESS,
    payload: ByTrendOverTimeList[]
}

interface getByTrendOverTimeError {
    type: typeof GET_ALL_TREND_OVER_TIME_ERROR,
    payload: string
}


interface getCollageListSuccess {
    type: typeof GET_COLLAGE_LİST_SUCCESS,
    payload: CollageList[]
}

interface getCollageListError {
    type: typeof GET_COLLAGE_LİST_ERROR,
    payload: string
}

interface getCollageDepartmentListSuccess {
    type: typeof GET_COLLAGE_DEPARTMENT_LİST_SUCCESS,
    payload: CollageList[]
}

interface getCollageDepartmentListError {
    type: typeof GET_COLLAGE_DEPARTMENT_LİST_ERROR,
    payload: string
}

interface getMemberProjectListSuccess {
    type: typeof GET_MEMBER_PROJECT_LİST_SUCCESS,
    payload: MemberProjectList[]
}

interface getMemberProjectListError {
    type: typeof GET_MEMBER_PROJECT_LİST_ERROR,
    payload: string
}

interface getMemberCourseListSuccess {
    type: typeof GET_MEMBER_COURSE_LİST_SUCCESS,
    payload: MemberCourseList[]
}

interface getMemberCourseListError {
    type: typeof GET_MEMBER_COURSE_LİST_ERROR,
    payload: string
}

interface getMemberTechnicListSuccess {
    type: typeof GET_MEMBER_TECHNİC_LİST_SUCCESS,
    payload: MemberCourseList[]
}

interface getMemberTechnicListError {
    type: typeof GET_MEMBER_TECHNİC_LİST_ERROR,
    payload: string
}

interface getPersonalAbilityListSuccess {
    type: typeof GET_PERSONAL_ABİLİTY_LİST_SUCCESS,
    payload: PersonalAbilityList[]
}

interface getPersonalAbilityListError {
    type: typeof GET_PERSONAL_ABİLİTY_LİST_ERROR,
    payload: string
}
interface getMemberGoodPersonalAbilityListSuccess {
    type: typeof GET_MEMBER_GOOD_PERSONAL_ABİLİTY_LİST_SUCCESS,
    payload: MemberGoodPersonalAbilityList[]
}

interface getMemberGoodPersonalAbilityListError {
    type: typeof GET_MEMBER_GOOD_PERSONAL_ABİLİTY_LİST_ERROR,
    payload: string
}
interface getMemberBadPersonalAbilityListSuccess {
    type: typeof GET_MEMBER_BAD_PERSONAL_ABİLİTY_LİST_SUCCESS,
    payload: MemberBadPersonalAbilityList[]
}

interface getMemberBadPersonalAbilityListError {
    type: typeof GET_MEMBER_BAD_PERSONAL_ABİLİTY_LİST_ERROR,
    payload: string
}
interface getMemberCollageListSuccess {
    type: typeof GET_MEMBER_COLLAGE_LİST_SUCCESS,
    payload: MemberCollageList[]
}

interface getMemberCollageListError {
    type: typeof GET_MEMBER_COLLAGE_LİST_ERROR,
    payload: string
}

interface getMemberLanguageListSuccess {
    type: typeof GET_MEMBER_LANGUAGE_LİST_SUCCESS,
    payload: MemberLanguageList[]
}

interface getMemberLanguageListError {
    type: typeof GET_MEMBER_LANGUAGE_LİST_ERROR,
    payload: string
}

interface getPositionListSuccess {
    type: typeof GET_POSITION_LIST_SUCCESS,
    payload: PositionList[]
}

interface getPositionListError {
    type: typeof GET_POSITION_LIST_ERROR,
    payload: string
}

interface getPositionListByCompanySuccess {
    type: typeof GET_POSITION_LIST_BY_COMPANY_SUCCESS,
    payload: PositionListByCompany[]
}

interface getPositionListByCompanyError {
    type: typeof GET_POSITION_LIST_BY_COMPANY_ERROR,
    payload: string
}

interface getPositionListByProjectSuccess {
    type: typeof GET_POSITION_LIST_BY_PROJECT_SUCCESS,
    payload: PositionListByProject[]
}

interface getPositionListByProjectError {
    type: typeof GET_POSITION_LIST_BY_PROJECT_ERROR,
    payload: string
}

interface getCustomerListSuccess {
    type: typeof GET_ALL_CUSTOMER_SUCCESS,
    payload: CustomerList[]
}

interface getCustomerListError {
    type: typeof GET_ALL_CUSTOMER_ERROR,
    payload: string
}

interface getMemberDomainListSuccess {
    type: typeof GET_MEMBER_DOMAİN_LİST_SUCCESS,
    payload: MemberDomainList[]
}

interface getMemberDomainListError {
    type: typeof GET_MEMBER_DOMAİN_LİST_ERROR,
    payload: string
}

interface getAllSubStatusListSuccess {
    type: typeof GET_ALL_SUB_STATUS_LİST_SUCCESS,
    payload: AllSubStatusList[]
}

interface getAllSubStatusListError {
    type: typeof GET_ALL_SUB_STATUS_LİST_ERROR,
    payload: string
}

interface getAllInterviwerListSuccess {
    type: typeof GET_ALL_INTERVIWER_LİST_SUCCESS,
    payload: AllSubStatusList[]
}

interface getAllInterviwerListError {
    type: typeof GET_ALL_INTERVIWER_LİST_ERROR,
    payload: string
}

interface getMemberTechnicDetailSuccess {
    type: typeof GET_MEMBER_TECHNİCAL_DETAİL_SUCCESS,
    payload: MemberTechnicDetailList[]
}

interface getMemberTechnicDetailError {
    type: typeof GET_MEMBER_TECHNİCAL_DETAİL_ERROR,
    payload: string
}

interface getPositionListTechnicGroupSuccess {
    type: typeof GET_POSITION_LIST_BY_TECHNIC_GROUP_SUCCESS,
    payload: PositionListTechnicGroup[]
}

interface getPositionListTechnicGroupError {
    type: typeof GET_POSITION_LIST_BY_TECHNIC_GROUP_ERROR,
    payload: string
}

interface getTechnicGroupNumberOfPeopleListSuccess {
    type: typeof GET_TECHNIC_GROUP_NUMBER_OF_PEOPLE_SUCCESS,
    payload: TechnicGroupNumberOfPeopleList[]
}

interface getTechnicGroupNumberOfPeopleListError {
    type: typeof GET_TECHNIC_GROUP_NUMBER_OF_PEOPLE_ERROR,
    payload: string
}

interface getTechnicGroupReportListSuccess {
    type: typeof GET_TECHNIC_GROUP_REPORT_SUCCESS,
    payload: TechnicGroupNumberOfPeopleList[]
}

interface getTechnicGroupReportListError {
    type: typeof GET_TECHNIC_GROUP_REPORT_ERROR,
    payload: string
}
interface getTechnicalSelectTreeListSuccess {
    type: typeof GET_TECHNICICAL_SELECT_TREE_LIST_SUCCESS,
    payload: TechnicalSelectTreeList[]
}

interface getTechnicalSelectTreeListError {
    type: typeof GET_TECHNICICAL_SELECT_TREE_LIST_ERROR,
    payload: string
}
interface getMainTechnologiesListSuccess {
    type: typeof GET_MAIN_TECHNOLOGIES_LIST_SUCCESS,
    payload: MainTechnologiesList[]
}

interface getMainTechnologiesListError {
    type: typeof GET_MAIN_TECHNOLOGIES_LIST_ERROR,
    payload: string
}
interface getSubTechnologiesListSuccess {
    type: typeof GET_SUB_TECHNOLOGIES_LIST_SUCCESS,
    payload: MainTechnologiesList[]
}

interface getSubTechnologiesListError {
    type: typeof GET_SUB_TECHNOLOGIES_LIST_ERROR,
    payload: string
}

interface getDomainListSuccess {
    type: typeof GET_DOMAİN_LİST_SUCCESS,
    payload: DomainList[]
}

interface getDomainListError {
    type: typeof GET_DOMAİN_LİST_ERROR,
    payload: string
}

//
interface getLanguageListSuccess {
    type: typeof GET_LANGUAGE_LİST_SUCCESS,
    payload: LanguageList[]
}

interface getLanguageListError {
    type: typeof GET_LANGUAGE_LİST_ERROR,
    payload: string
}

interface getLanguageLevelListSuccess {
    type: typeof GET_LANGUAGE_LEVEL_LİST_SUCCESS,
    payload: LanguageLevelList[]
}

interface getLanguageLevelListError {
    type: typeof GET_LANGUAGE_LEVEL_LİST_ERROR,
    payload: string
}

interface getPersonalCommunicationLevelListSuccess {
    type: typeof GET_PERSONAL_COMMUNİCATİON_LEVEL_LİST_SUCCESS,
    payload: PersonalCommunicationLevelList[]
}

interface getPersonalCommunicationLevelListError {
    type: typeof GET_PERSONAL_COMMUNİCATİON_LEVEL_LİST_ERROR,
    payload: string
}
interface getMemberPersonalSkillListSuccess {
    type: typeof GET_MEMBER_PERSONAL_SKİLL_LİST_SUCCESS,
    payload: MemberPersonalSkillList[]
}

interface getMemberPersonalSkillListError {
    type: typeof GET_MEMBER_PERSONAL_SKİLL_LİST_ERROR,
    payload: string
}
interface getSubTechnologiesListbyMainGroupSuccess {
    type: typeof GET_SUB_TECHNOLOGIES_LİST_BY_MAİN_GROUP_SUCCESS,
    payload: SubTechnologiesListbyMainGroup[]
}

interface getSubTechnologiesListbyMainGroupError {
    type: typeof GET_SUB_TECHNOLOGIES_LİST_BY_MAİN_GROUP_ERROR,
    payload: string
}

interface getTechnicGroupMainListSuccess {
    type: typeof GET_TECHNİC_GROUP_MAİN_LİST_SUCCESS,
    payload: SubTechnologiesListbyMainGroup[]
}

interface getTechnicGroupMainListError {
    type: typeof GET_TECHNİC_GROUP_MAİN_LİST_ERROR,
    payload: string
}

interface getMemberTechnicGroupListSuccess {
    type: typeof GET_MEMBER_TECHNİC_GROUP_LİST_SUCCESS,
    payload: MemberTechnicGroupList[]
}

interface getMemberTechnicGroupListError {
    type: typeof GET_MEMBER_TECHNİC_GROUP_LİST_ERROR,
    payload: string
}

interface getStatusStatisticListSuccess {
    type: typeof GET_STATUS_STATİSTİC_LİST_SUCCESS,
    payload: StatusStatisticList[]
}

interface getStatusStatisticListError {
    type: typeof GET_STATUS_STATİSTİC_LİST_ERROR,
    payload: string
}

interface getStatusStatisticbyMemberListSuccess {
    type: typeof GET_STATUS_STATİSTİC_BY_MEMBER_LİST_SUCCESS,
    payload: StatusStatisticbyMemberList[]
}

interface getStatusStatisticbyMemberListError {
    type: typeof GET_STATUS_STATİSTİC_BY_MEMBER_LİST_ERROR,
    payload: string
}

interface getAllMemberListForPositionSuccess {
    type: typeof GET_ALL_MEMBER_LİST_FOR_POSİTİON_SUCCESS,
    payload: AllMemberListForPosition[]
}

interface getAllMemberListForPositionError {
    type: typeof GET_ALL_MEMBER_LİST_FOR_POSİTİON_ERROR,
    payload: string
}

interface getDomainMainGroupListSuccess {
    type: typeof GET_DOMAİN_MAİN_GROUP_LİST_SUCCESS,
    payload: DomainMainGroupList[]
}

interface getDomainMainGroupListError {
    type: typeof GET_DOMAİN_MAİN_GROUP_LİST_ERROR,
    payload: string
}

interface getDomainSubGroupListSuccess {
    type: typeof GET_DOMAİN_SUB_GROUP_LİST_SUCCESS,
    payload: DomainSubGroupList[]
}

interface getDomainSubGroupListError {
    type: typeof GET_DOMAİN_SUB_GROUP_LİST_ERROR,
    payload: string
}

export type memberActions = getAllInterviwerListSuccess | getAllInterviwerListError | getPositionListTechnicGroupSuccess | getPositionListTechnicGroupError |
    getCustomerListSuccess | getCustomerListError | getPositionListByProjectSuccess | getPositionListByProjectError | getPositionListByCompanyError |
    getPositionListByCompanySuccess | getPositionListError | getPositionListSuccess | getByTrendOverTimeSuccess | getByTrendOverTimeError
    | getYearsOfExperienceSalaryExpectationSuccess | getYearsOfExperienceSalaryExpectationError | getTechnicalGroupSalaryExpectationSuccess | getTechnicalGroupSalaryExpectationError
    | getMemberListSuccess | getMemberListError | getMemberAllListSuccess | getMemberAllLisrtError | getMyUserDetailListSuccess | getSearchedPersonelDetailListSuccess | getMyUserDetailListError
    | getAllPersonnelListSuccess | getAllPersonnelListError | getAllPerformanceCriteriaSuccess | getAllPerformanceCriteriaError | getComponiesSuccess | getComponiesError
    | getCustomerListByCompanyIdSuccess | getCustomerListByCompanyIdError | getTeamListByCustomerSuccess | getTeamListByCustomerError | getManagerCustomerIdSuccess
    | getManagerCustomerIdError | getEmployeeByIdSuccess | getEmployeeByIdError | getListPerformanceEvulationSuccess | getListPerformanceEvulationError
    | getPerformaceDetailSuccess | getPerformanceDetailError | getBirthdaySuccess | getBirthdayError | getCandidateListError | getCandidateListSuccess
    | getTechnicalGroupListSuccess | getTechnicalGroupListError | getCollageListError | getCollageListSuccess | getCollageDepartmentListError | getCollageDepartmentListSuccess
    | getMemberProjectListSuccess | getMemberProjectListError | getMemberCourseListSuccess | getMemberCourseListError | getMemberTechnicListSuccess | getMemberTechnicListError
    | getPersonalAbilityListError | getPersonalAbilityListSuccess | getMemberBadPersonalAbilityListError | getMemberBadPersonalAbilityListSuccess | getMemberGoodPersonalAbilityListError | getMemberGoodPersonalAbilityListSuccess
    | getMemberCollageListSuccess | getMemberCollageListError | getMemberLanguageListError | getMemberLanguageListSuccess | getMemberDomainListSuccess | getMemberDomainListError
    | getAllSubStatusListSuccess | getAllSubStatusListError | getMemberTechnicDetailSuccess | getMemberTechnicDetailError | getTechnicGroupNumberOfPeopleListError
    | getTechnicGroupNumberOfPeopleListSuccess | getTechnicGroupReportListSuccess | getTechnicGroupReportListError | getTechnicalSelectTreeListSuccess | getTechnicalSelectTreeListError
    | getMainTechnologiesListSuccess | getMainTechnologiesListError | getSubTechnologiesListSuccess | getSubTechnologiesListError | getDomainListSuccess | getDomainListError | getPersonalCommunicationLevelListSuccess | getPersonalCommunicationLevelListError
    | getMemberPersonalSkillListSuccess | getMemberPersonalSkillListError | getSubTechnologiesListbyMainGroupSuccess | getSubTechnologiesListbyMainGroupError | getTechnicGroupMainListSuccess | getTechnicGroupMainListError | getMemberTechnicGroupListError | getMemberTechnicGroupListSuccess
    | getStatusStatisticListError | getStatusStatisticListSuccess | getStatusStatisticbyMemberListSuccess | getStatusStatisticbyMemberListError | getAllMemberListForPositionSuccess | getAllMemberListForPositionError | getLanguageListSuccess | getLanguageListError | getLanguageLevelListError | getLanguageLevelListSuccess
    | getDomainMainGroupListError | getDomainMainGroupListSuccess | getDomainSubGroupListSuccess | getDomainSubGroupListError;
