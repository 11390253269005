import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  Typography,
  TextField,
  TableCell,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  Snackbar,
  DialogContentText,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import logo from "../../../static/logo.png";
import axios from "axios";
import { SERVICEURL } from "../../../appconfig";
import { verifyHash } from "../../../utils/hash";
import queryString from "query-string";
import { StyledContainer } from "./style";

type EvaluationFormParams = {
  evaluationFormId: string;
};

type FormDetail = {
  EvaluationFormID: number;
  QuestionID: number;
  Answer: string;
};

const JobResignationForm = () => {
  const resignationReasons: Record<string, string[]> = {
    İstifa: [
      "Ücret",
      "Yan Haklar",
      "İş Tatminsizliği",
      "Kurum Kültürü",
      "Yönetsel Faktörler",
      "Sağlık",
      "Eğitim ve Gelişim Fırsatları",
      "Akademik Kariyer",
      "Çalışma Ortamı ve Lokasyon",
      "Şehir Değişikliği",
      "Ülke Değişikliği",
      "Diğer (Detaylandırınız)",
    ],
    "İş Akdi Feshi": [
      "Askerlik",
      "Emeklilik",
      "Evlilik",
      "Diğer (Detaylandırınız)",
    ],
  };

  const subQuestions: Record<string, string[]> = {
    3: ["Ücret farkı", "Yan haklar farkı", "Geçiş yapılacak firma ve sektör"],
    6: ["En çok beğendiklerin", "En az beğendiklerin"],
  };

  const locationData = useLocation();
  const { isHR } = queryString.parse(locationData.search);
  const { evaluationFormId } = useParams<EvaluationFormParams>();
  const [resignationType, setResignationType] = useState(""); // Seçilen işten ayrılış şekli
  const [selectedReasons, setSelectedReasons] = useState<string[]>([]); // Seçilen nedenler
  const [otherReason, setOtherReason] = useState(""); // "Diğer" için detay alanı
  const [showExpErrorSnackbar, setShowExpErrorSnackbar] = useState(false);
  const [formData, setFormData] = useState<any>({});
  const [showEmployeeInfo, setShowEmployeeInfo] = useState(true);
  const [formQuestions, setFormQuestions] = useState<String[]>([]);
  const [answers, setAnswers] = useState(Array(formQuestions.length).fill(""));
  const reasons = resignationType ? resignationReasons[resignationType] : [];
  const [showConfirmSubmit, setShowConfirmSubmit] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [showUnsavedAlert, setShowUnsavedAlert] = useState(false);
  const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
  const [showSendErrorSnackbar, setShowSendErrorSnackbar] = useState(false);
  const [showSendSuccessSnackbar, setShowSendSuccessSnackbar] = useState(false);
  const [possibleIDs, setPossibleIDs] = useState([]);
  const [matchingID, setMatchingID] = useState();
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(true);  
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState("");
  const splitter = "~~";
  
  const mytoken = localStorage.getItem("token");
  const HEADER = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + mytoken,
    },
  };

  useEffect(() => {
    if (mytoken != null) {
      setIsLoggedIn(true);
      setShowEmployeeInfo(true)
    } else {
      setIsLoggedIn(false);
      setShowEmployeeInfo(false)
    }
  }, [mytoken]);
  
  // Hash icin ID'leri al
  useEffect(() => {
    if (!evaluationFormId) return;
    axios
      .get(SERVICEURL + "api/Data/GetFormID", HEADER)
      .then((response) => {
        setPossibleIDs(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  }, [evaluationFormId]);

  // Dogrulama yap
  useEffect(() => {
    if (possibleIDs.length > 0) {
      const matchedID = possibleIDs.find((id) =>
        verifyHash(evaluationFormId, id)
      );
      setMatchingID(matchedID);
    }
  }, [possibleIDs, evaluationFormId]);

  // Form gecerli mi
  useEffect(() => {
    if (matchingID) {
      const input = {
        EvaluationFormID: matchingID,
      };

      axios
        .post(SERVICEURL + "api/Data/IsFormValid", input, HEADER)
        .then((response) => {
          setIsFormValid(response.data.IsFormValid);
          setErrorMessage(response.data.Message);
        })
        .catch((error) => {
          console.error("Error fetching data: ", error);
        });
    }
  }, [matchingID]);
  

  const handleReasonChange = (reason: string) => {
    if (selectedReasons.includes(reason)) {
      // Seçenek kaldırıldığında listeyi güncelle
      const updatedReasons = selectedReasons.filter((r) => r !== reason);
      setSelectedReasons(updatedReasons);
    } else {
      // Yeni seçenek eklendiğinde listeyi güncelle
      setSelectedReasons([...selectedReasons, reason]);
    }
    setIsSaved(false);
  };

  const getReasonIndex = (reason: string) => {
    const index = selectedReasons.indexOf(reason);
    return index !== -1 ? index + 1 : null; // Sıfırdan başlamak yerine 1'den başlayacak
  };

  const handleResignationTypeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const type = event.target.value;
    setResignationType(type);
    setSelectedReasons([]); // Seçenekleri sıfırla
    setOtherReason(""); // "Diğer" açıklamasını sıfırla
  };

  // Ustteki bilgiler icin
  useEffect(() => {
    if (matchingID && (isFormValid || isLoggedIn)) {
      const input = {
        EvaluationFormID: matchingID,
      };

      axios
        .post(SERVICEURL + "api/Data/GetEvaluationFormData", input, HEADER)
        .then((response) => {
          setFormData(response.data);
        })
        .catch((error) => {
          console.error("Error fetching data: ", error);
        });
    }
  }, [matchingID,isFormValid,isLoggedIn]);

  // Form sorulari icin
  useEffect(() => {
    if (matchingID && (isFormValid || isLoggedIn)) {
      const input = {
        EvaluationFormID: matchingID,
      };

      axios
        .post(SERVICEURL + "api/Data/GetFormQuestions", input, HEADER)
        .then((response) => {
          setFormQuestions(response.data);
        })
        .catch((error) => {
          console.error("Error fetching data: ", error);
        });
    }
  }, [matchingID,isFormValid,isLoggedIn]);

  // Form cevaplari icin
  useEffect(() => {
    if (matchingID && isLoggedIn) {
      const input = {
        EvaluationFormID: matchingID,
      };
      axios
        .post(SERVICEURL + "api/Data/GetEvaluationFormAnswers", input, HEADER)
        .then((response) => {
          if (response.data.length > 0) {
            setAnswers(response.data.slice(0, -1));
            setResignationType(response.data[8]);
            const reasons = response.data[9].split("~~");
            let otherReason = "";
            if (reasons.includes("Diğer (Detaylandırınız)")) {
              otherReason = reasons[reasons.length - 1]; // Son öğeyi otherReason olarak belirle
            }            
            setSelectedReasons(reasons);
            setOtherReason(otherReason);
          }
        })
        .catch((error) => {
          console.error("Detaylar getirilemedi:", error);
        });
    }
  }, [matchingID,isLoggedIn]);

  const handleAnswerChange = (index: number, value: string) => {
    const updatedAnswers = [...answers];
    updatedAnswers[index] = value;
    setAnswers(updatedAnswers);
    setIsSaved(false);
  };

  const handleSave = () => {
    const isAnyAnswerEmpty = answers.length === 0 || answers.some((answer) => !answer || answer.trim() === "");
    if (isAnyAnswerEmpty) {
      setShowErrorSnackbar(true);
      return;
    }

    if(resignationType === ""){
      setShowErrorSnackbar(true);
      return;
    }

    if (resignationType !== "Kadroya Geçiş" && selectedReasons.length === 0) {
      setShowErrorSnackbar(true);
      return;
    }

    if(selectedReasons.includes("Diğer (Detaylandırınız)") && otherReason == "")
    {
      setShowExpErrorSnackbar(true);
    }
    setShowSuccessSnackbar(true);
    setIsSaved(true);
  };

  const handleSend = () => {
    if (!isSaved) {
      setShowUnsavedAlert(true);
    } else {
      setShowConfirmSubmit(true);
    }
  };

  const confirmSubmit = () => {
    setShowConfirmSubmit(false);

    if(matchingID){
      const formDetails: FormDetail[] = formQuestions
      .slice(0, -2)
      .map((question: any, index: any) => {
        if (subQuestions[index]) {
          const altAnswers = subQuestions[index]
            .map((_, subIndex) =>
              answers[index]?.split(splitter)[subIndex]?.trim()
            )
            .filter((a) => a)
            .join(splitter);
          return {
            EvaluationFormID: parseInt(matchingID),
            QuestionID: index + 1,
            Answer: altAnswers,
          };
        } else {
          return {
            EvaluationFormID: parseInt(matchingID),
            QuestionID: index + 1,
            Answer: answers[index]?.trim() || null,
          };
        }
      });

    // İşten ayrılış şekli (9. soru) için özel ekleme
    formDetails.push({
      EvaluationFormID: parseInt(matchingID),
      QuestionID: 9,
      Answer: resignationType,
    });

    // İşten ayrılma nedenleri (10. soru)
    let reasonsAnswer = selectedReasons.join(splitter);

    // "Diğer (Detaylandırınız)" seçildiyse, otherReason'u ekle
    if (selectedReasons.includes("Diğer (Detaylandırınız)")) {
      reasonsAnswer += `${splitter}${otherReason.trim()}`;
    }

    formDetails.push({
      EvaluationFormID: parseInt(matchingID),
      QuestionID: 10,
      Answer: reasonsAnswer,
    });
    console.log("formDetails : ",formDetails)
    axios
      .post(
        SERVICEURL + "api/Data/SaveEvaluationFormDetails",
        formDetails,
        HEADER
      )
      .then((response) => {
        if (response.data.IsOk) {
          setShowSendSuccessSnackbar(true);
        } else {
          setShowSendErrorSnackbar(true);
        }
      })
      .catch((error) => {
        console.error("Hata oluştu:", error);
      });
    }
  };

  // Eğer form geçerli değilse ve giris yapilmadiysa hata mesajını göster
  if (!isLoggedIn && !isFormValid) {
    // Eğer form geçerli değilse hata mesajını göster
    return (
      <div 
        style={{
        backgroundColor: "#f7faff",
        minHeight: "100vh",
        padding: "20px",
      }}
      >
        {/* Logo */}
        <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "20px",
        }}
        >
          <img src={logo} alt="RDC BOARD"></img>
        </Box>
        <Box>
        <Box>
          <Typography
            variant="h5"
            style={{ textAlign: "center", color: "black" }}
          >
            {errorMessage}
          </Typography>
        </Box>
      </Box>
      </div>      
    );
  }

  // Eğer form geçerli ise ya da giris yapildiysa sayfayi goster
  if (isFormValid || isLoggedIn) {
  return (
    <StyledContainer>
      {/* Logo */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "20px",
        }}
      >
        <img src={logo} alt="RDC BOARD"></img>
      </Box>

      <Box
        sx={{
          padding: "20px",
          borderRadius: "8px",
          boxShadow: "0 2px 10px rgba(0,0,0,0.1)",
          maxWidth: "1000px",
          margin: "20px auto",
          backgroundColor: "white",
        }}
      >
        <Typography
          variant="h5"
          sx={{ marginBottom: "20px", textAlign: "center", fontWeight: "bold" }}
        >
          İşten Çıkış Mülakat Değerlendirme Formu
        </Typography>

        {/* Çalışan Bilgileri */}
        {showEmployeeInfo && (
          <TableContainer
            component={Paper}
            elevation={3}
            sx={{ marginBottom: "20px" }}
          >
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell
                    style={{
                      fontWeight: "bold",
                      width: "40%",
                      borderRight: "1px solid #ccc",
                    }}
                  >
                    İşten Ayrılan Kişinin Adı Soyadı:
                  </TableCell>
                  <TableCell>
                    {Array.isArray(formData) && formData.length > 0
                      ? formData[0].MemberName
                      : ""}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{
                      fontWeight: "bold",
                      width: "40%",
                      borderRight: "1px solid #ccc",
                    }}
                  >
                    Çalıştığı Müşteri:
                  </TableCell>
                  <TableCell>
                    {Array.isArray(formData) && formData.length > 0
                      ? formData[0].CustomerName
                      : ""}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{
                      fontWeight: "bold",
                      width: "40%",
                      borderRight: "1px solid #ccc",
                    }}
                  >
                    Çalışma Süresi:
                  </TableCell>
                  <TableCell>
                    {Array.isArray(formData) && formData.length > 0
                      ? formData[0].Experience
                      : ""}{" "}
                    {}
                    Gün
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{
                      fontWeight: "bold",
                      width: "40%",
                      borderRight: "1px solid #ccc",
                    }}
                  >
                    Formun Gittiği Tarih:
                  </TableCell>
                  <TableCell>
                    {Array.isArray(formData) && formData.length > 0
                      ? formData[0].FormDate
                      : ""}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        )}

        {/* Sorular ve Yanıt Alanları */}
        <Box>
          {formQuestions.slice(0, -2).map((question, index) => (
            <Box key={index} sx={{ marginBottom: "20px" }}>
              <Typography variant="h6">
                {index + 1}) {question}
              </Typography>
              {/* Alt Sorular Kontrolü */}
              {index in subQuestions ? (
                <Box sx={{ paddingLeft: "10px", marginTop: "10px" }}>
                  {subQuestions[index].map((subQuestion, subIndex) => (
                    <Box key={subIndex} sx={{ marginBottom: "10px" }}>
                      <Typography>{subQuestion}:</Typography>
                      <TextField
                        fullWidth
                        placeholder="Yanıtınızı girin"
                        value={answers[index]?.split(splitter)[subIndex] || ""} // Alt soruları ayır ve ilgili yanıtı al
                        onChange={(e) => {
                          const updatedSubAnswers =
                            answers[index]?.split(splitter) || [];
                          updatedSubAnswers[subIndex] = e.target.value;
                          const updatedAnswer = updatedSubAnswers
                            .filter((a: any) => a) // Boş değerleri at
                            .join(splitter);
                          handleAnswerChange(index, updatedAnswer);
                        }}
                        sx={{ marginBottom: "10px" }}
                      />
                    </Box>
                  ))}
                </Box>
              ) : (
                /* Normal Sorular */
                <TextField
                  fullWidth
                  multiline
                  sx={{ paddingLeft: "10px" }}
                  rows={2}
                  placeholder="Yanıtınızı girin"
                  value={answers[index] || ""}
                  onChange={(e) => handleAnswerChange(index, e.target.value)}
                />
              )}
            </Box>
          ))}
        </Box>

        {/* İşten Ayrılış Şekli */}
        <Typography
          variant="h6"
          sx={{ fontWeight: "bold", marginBottom: "10px" }}
        >
          İşten Ayrılış Şekli
        </Typography>
        <RadioGroup
          value={resignationType}
          onChange={handleResignationTypeChange}
          row
        >
          <FormControlLabel value="İstifa" control={<Radio />} label="İstifa" />
          <FormControlLabel
            value="İş Akdi Feshi"
            control={<Radio />}
            label="İş Akdi Feshi"
          />
          <FormControlLabel
            value="Kadroya Geçiş"
            control={<Radio />}
            label="Kadroya Geçiş"
          />
        </RadioGroup>

        {/* İşten Ayrılış Nedeni */}
        {(resignationType === "İstifa" ||
          resignationType === "İş Akdi Feshi") && (
          <Box
            sx={{
              marginTop: "20px",
              padding: "20px",
              border: "1px solid #ccc",
              borderRadius: "8px",
            }}
          >
            {reasons.map((reason: string, index: number) => (
            <Box
              key={index}
              sx={{
                marginBottom: "10px",
                display: "flex",
                alignItems: "flex-start",
                flexDirection: "column",
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedReasons.includes(reason)}
                    onChange={() => handleReasonChange(reason)}
                  />
                }
                label={`${reason} ${
                  getReasonIndex(reason) ? `(${getReasonIndex(reason)})` : ""
                }`}
              />
              {/* "Diğer" seçeneği için detay alanı */}
              {reason === "Diğer (Detaylandırınız)" &&
                selectedReasons.includes(reason) && (
                  <Box sx={{ marginTop: "10px", width: "100%" }}>
                    <TextField
                      fullWidth
                      multiline
                      rows={3}
                      value={otherReason}
                      onChange={(e) => setOtherReason(e.target.value)}
                      placeholder="Detaylı açıklamanızı buraya girin"
                      error={otherReason.trim() === ""}
                      helperText={
                        otherReason.trim() === ""
                          ? "Bu alan zorunludur."
                          : ""
                      }
                    />
                  </Box>
                )}
            </Box>
          ))}
          </Box>
        )}
        {/* Kaydet ve Gönder Butonları */}
        {!isHR && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              gap: "10px",
              marginTop: "20px",
            }}
          >
            <Button
              variant="outlined"
              onClick={handleSave}
              style={{ backgroundColor: "white" }}
            >
              Kaydet
            </Button>
            <Button variant="contained" color="primary" onClick={handleSend}>
              Gönder
            </Button>
          </Box>
        )}
      </Box>

      {/* Kaydedilmemiş Uyarısı */}
      <Dialog
        open={showUnsavedAlert}
        onClose={() => setShowUnsavedAlert(false)}
        disableScrollLock
      >
        <DialogTitle>Uyarı</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Lütfen değişiklikleri kaydediniz.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowUnsavedAlert(false)}>Tamam</Button>
        </DialogActions>
      </Dialog>

      {/* Gönderme Onayı */}
      <Dialog
        open={showConfirmSubmit}
        onClose={() => setShowConfirmSubmit(false)}
        disableScrollLock
      >
        <DialogTitle>Onay</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Formu gönderdikten sonra üzerinde değişiklik yapamazsınız. Onaylıyor
            musunuz?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowConfirmSubmit(false)}>Hayır</Button>
          <Button onClick={confirmSubmit} color="primary">
            Evet
          </Button>
        </DialogActions>
      </Dialog>

      {/* Başarı Snackbar */}
      <Snackbar
        open={showSuccessSnackbar}
        autoHideDuration={2000}
        onClose={() => setShowSuccessSnackbar(false)}
        message="Değişiklikler kaydedildi."
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      />
      <Snackbar
        open={showSendSuccessSnackbar}
        autoHideDuration={2000}
        onClose={() => setShowSendSuccessSnackbar(false)}
        message="Başarıyla gönderildi."
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      />

      {/* Hata Snackbar */}
      <Snackbar
        open={showErrorSnackbar}
        autoHideDuration={2000}
        onClose={() => setShowErrorSnackbar(false)}
        message="Lütfen tüm soruları cevaplayınız."
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      />
      <Snackbar
        open={showSendErrorSnackbar}
        autoHideDuration={2000}
        onClose={() => setShowSendErrorSnackbar(false)}
        message="Form zaten mevcut."
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      />
      <Snackbar
        open={showExpErrorSnackbar}
        autoHideDuration={2000}
        onClose={() => setShowExpErrorSnackbar(false)}
        message="Lütfen açıklama giriniz."
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      />
    </StyledContainer>
  );
  }

  // Default Sayfa
  return (
    <div
      style={{
        backgroundColor: "#f7faff",
        minHeight: "100vh",
        padding: "20px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Typography variant="h5" style={{ color: "gray" }}>
        Yükleniyor...
      </Typography>
    </div>
  )
};

export default JobResignationForm;
