/* eslint-disable @typescript-eslint/no-unsafe-return */
import styled, { themeGet, device, css } from "../../../../shared/styled";
import tinycolor2 from "tinycolor2";
import { Anchor } from "../anchor/Anchor";

interface ComponentProps {
  darkModeActive?: boolean;
}

export const StyledNavbar = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  color: #485e9029;
  display: flex; 
  flex-direction: column; 
  white-space: nowrap;
  ${device.large} {
    flex-wrap: wrap;
    flex-direction: row; 
  }
`;

type NavItemProps = {
  $hasSubmenu: boolean;
};

export const StyledNavitem = styled.li<NavItemProps>`
  ${({ $hasSubmenu }) =>
    $hasSubmenu &&
    css`
      position: relative;
      & > a {
      padding: 0.5rem 1rem;
        ${device.large} {
          padding: 0 14px 0 0;
        }
        &:after {
          position: absolute;
          right: 20px;
          top: 5px;
          content: "";
          width: 4px;
          height: 4px;
          border-right: 1.5px solid #8392a5;
          border-bottom: 1.5px solid #8392a5;
          transform: rotate(45deg);
          transition: all 0.2s ease-in-out;
          ${device.large} {
            margin-left: 6px;
            right: 0;
          }
        }
      }
    `}
  &:not(:first-of-type) {
    margin-top: 0px;
  }
  ${device.large} {
    display: inline-block;
    margin-left: 25px;
    &:first-child {
      margin-left: 0;
    }
  }
  ${device.xlarge} {
    margin-left: 30px;
  }
`;

export const StyledNavlink = styled(({ ...rest }) => (
  <Anchor {...rest} />
))<ComponentProps>`
  display: flex;
  align-items: center;
  font-weight: 500;
  color: #001737;
  transition: all 0.2s ease-in-out;
  position: relative;
  font-size: 0.875rem;
  padding: 0 20px;
  text-decoration: none;
  ${device.large} {
    padding: 0;
    & > svg {
      display: none;
    }
  }
  svg {
    width: 18px;
    height: 18px;
    stroke-width: 2.2px;
    margin-right: 10px;
    color: #7987a1;
    transition: all 0.2s ease-in-out;
  }
  &:hover {
    color: #0168fa;
    svg {
      color: #0168fa;
    }
  }
  &:focus {
    outline: none;
  }
  ${({ darkModeActive }) =>
    darkModeActive &&
    css`
      color: #cdd4e0;
    `}
`;

export const StyledSubmenu = styled.ul<ComponentProps>`
  position: relative;
  min-width: auto;
  list-style: none;
  padding: 0 15px 3px 24px;
  border-left: 1px solid #485e9029;
  margin-top: 10px;
  margin-left: 28px;
  z-index: 1000;
  display: none;
  &.open {
    display: block;
  }
  ${device.large} {
    position: absolute;
    top: 29.5px;
    left: -25px;
    background-color: #fff;
    padding: 20px 25px;
    border: 1px solid #485e9029;
    margin-top: 10;
    margin-left: 0;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    &:before {
      content: "";
      position: absolute;
      width: 12px;
      height: 12px;
      top: -7px;
      left: 25px;
      border-bottom: 1.5px solid;
      border-right: 1.5px solid;
      transform: rotate(-135deg);
      background: #3a475f;
    }
    ${({ darkModeActive }) =>
      darkModeActive &&
      css`
        background-color: #3a475f;
        border-width: 0;
      `}
  }
`;

export const StyledSubNavItem = styled.li`
  margin-top: 5px;
  &:first-child {
    margin-top: 0;
  }
`;

export const StyledSubNavlink = styled(({ ...rest }) => (
  <Anchor {...rest} />
))<ComponentProps>`
  color: #1b2e4b;
  white-space: nowrap;
  font-size: 13px;
  display: flex;
  align-items: center;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  &:hover {
    color: #0168fa;
  }
  svg {
    width: 16px;
    height: 16px;
    display: none;
    ${device.small} {
      margin-right: 10px;
    }
    ${device.xxlarge} {
      margin-right: 10px;
    }
    ${device.large} {
      display: block;
    }
  }
  ${({ darkModeActive }) =>
    darkModeActive &&
    css`
      color: #cdd4e0;
    `}
`;

export const StyledMegaMenu = styled.div<ComponentProps>`
  position: relative;
  min-width: 200px;
  list-style: none;
  padding: 0 15px 3px 24px;
  border-left: 1px solid ${themeGet("colors.border")};
  margin-top: 10px;
  margin-left: 28px;
  z-index: 1000;
  display: none;
  &.open {
    display: block;
  }
  ${device.large} {
    position: absolute;
    top: 37.5px;
    left: -25px;
    background-color: #fff;
    padding: 20px 25px;
    border: 1px solid
      ${({ darkModeActive }) =>
        tinycolor2(themeGet("colors.text4")(darkModeActive))
          .setAlpha(0.53)
          .toString()};
    margin-top: 0;
    margin-left: 0;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    &:before {
      content: "";
      position: absolute;
      width: 12px;
      height: 12px;
      top: -7px;
      left: 25px;
      border-bottom: 1.5px solid
        ${({ darkModeActive }) =>
          tinycolor2(themeGet("colors.text4")(darkModeActive))
            .setAlpha(0.53)
            .toString()};
      border-right: 1.5px solid
        ${({ darkModeActive }) =>
          tinycolor2(themeGet("colors.text4")(darkModeActive))
            .setAlpha(0.53)
            .toString()};
      transform: rotate(-135deg);
      background: #fff;
    }
    ${({ darkModeActive }) =>
      darkModeActive &&
      css`
        background-color: ${tinycolor2(
          themeGet("colors.gray900")(darkModeActive)
        )
          .lighten(5)
          .toString()};
        border-width: 0;
      `}
  }
`;

export const StyledMegaMenuInner = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  min-width: 100%;
  ${device.large} {
    min-width: 425px;
  }
`;

export const StyledMegamenuList = styled.ul`
  flex-basis: 100%;
  max-width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  &:not(:first-of-type) {
    margin-top: 10px;
  }
  ${device.large} {
    flex-basis: 47%;
    max-width: 47%;
    &:nth-of-type(even) {
      margin-left: 25px;
      padding-left: 25px;
      border-left: 1px solid ${themeGet("colors.border")};
    }
    &:not(:nth-of-type(-n + 2)) {
      padding-top: 10px;
    }
  }
`;

export const StyledMegamenuLabel = styled.li<ComponentProps>`
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 0.7px;
  color: ${themeGet("colors.text3")};
  font-family: ${themeGet("fonts.interUi")};
  text-transform: uppercase;
  display: block;
  margin-bottom: 15px;
  ${({ darkModeActive }) =>
    darkModeActive &&
    css`
      color: ${themeGet("colors.gray700")};
    `}
`;
