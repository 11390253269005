import { combineReducers } from "redux";
import {
    memberReducer, byTrendOverTimeReducer, allMemberReducer, myUserDetailReducer, allPersonnelReducer, allCriteriaReducer, companiesReducer, customerReducer,
    teamReducer, managerReducer, employeeReducer, performanceReducer, performanceDetailReducer, birthdayReducer, candidateReducer, technicalReducer, collageListReducer,
    collageDepartmentListReducer, technicalGroupSalaryExpectationReducer, yearsOfExperienceSalaryExpectationReducer, memberProjectListReducer, memberCourseListReducer
    , memberTechnicListReducer, personalAbilityListReducer, memberCollageListReducer, memberBadPersonalAbilityListReducer, memberGoodPersonalAbilityListReducer,
    memberLanguageListReducer, positionListReducer, positionListByCompanyReducer, positionListByProjectReducer, customerListReducer, memberDomainListReducer,
    allSubStatusListReducer, memberTechnicDetailListReducer, positionListByTechnicGroupReducer, allInterviwerListReducer, technicGroupNumberOfPeopleListReducer,
    technicGroupReportListReducer, technicalSelectTreeListReducer, mainTechnologiesListReducer, subTechnologiesListReducer, domainListReducer, personalCommunicationLevelListReducer,
    memberPersonalSkillListReducer, subTechnologiesListbyMainGroupStateReducer, technicGroupMainListReducer, memberTechnicGroupListReducer, statusStatisticListReducer, statusStatisticbyMemberListReducer,
    allMemberListForPositionReducer, languageListReducer, languageLevelListReducer, domainMainGroupListReducer, domainSubGroupListReducer,
    searchedPersonelDetailReducer
} from "./memberList";
import uiReducer from "../slices/ui";
//import cartReducer from "./memberList";


const rootReducer = combineReducers({
    member: memberReducer,
    ui: uiReducer,
    allMember: allMemberReducer,
    myUserDetail: myUserDetailReducer,
    searchedPersonelDetail: searchedPersonelDetailReducer,
    allPersonnel: allPersonnelReducer,
    allCriteria: allCriteriaReducer,
    companies: companiesReducer,
    customer: customerReducer,
    team: teamReducer,
    manager: managerReducer,
    employee: employeeReducer,
    performance: performanceReducer,
    performanceDetail: performanceDetailReducer,
    birthdayList: birthdayReducer,
    candidateList: candidateReducer,
    technicalList: technicalReducer,
    technicalGroupSalaryExpectationList: technicalGroupSalaryExpectationReducer,
    yearsOfExperienceSalaryExpectationList: yearsOfExperienceSalaryExpectationReducer,
    byTrendOverTimeList: byTrendOverTimeReducer,
    collageList: collageListReducer,
    collageDepartmentList: collageDepartmentListReducer,
    memberProjectList: memberProjectListReducer,
    memberCourseList: memberCourseListReducer,
    memberTechnicList: memberTechnicListReducer,
    personalAbilityList: personalAbilityListReducer,
    memberBadPersonalAbilityList: memberBadPersonalAbilityListReducer,
    memberGoodPersonalAbilityList: memberGoodPersonalAbilityListReducer,
    memberCollageList: memberCollageListReducer,
    memberLanguageList: memberLanguageListReducer,
    positionList: positionListReducer,
    positionListByCompany: positionListByCompanyReducer,
    positionListByProject: positionListByProjectReducer,
    customers: customerListReducer,
    memberDomainList: memberDomainListReducer,
    allSubStatusList: allSubStatusListReducer,
    memberTechnicDetailList: memberTechnicDetailListReducer,
    positionListByTechnicGroup: positionListByTechnicGroupReducer,
    allInterviwerList: allInterviwerListReducer,
    technicGroupNumberOfPeopleList: technicGroupNumberOfPeopleListReducer,
    technicGroupReportList: technicGroupReportListReducer,
    technicalSelectTreeList: technicalSelectTreeListReducer,
    mainTechnologiesList: mainTechnologiesListReducer,
    subTechnologiesList: subTechnologiesListReducer,
    domainList: domainListReducer,
    languageList: languageListReducer,
    languageLevelList: languageLevelListReducer,
    personalCommunicationLevelList: personalCommunicationLevelListReducer,
    memberPersonalSkillList: memberPersonalSkillListReducer,
    subTechnologiesListbyMainGroup: subTechnologiesListbyMainGroupStateReducer,
    technicGroupMainList: technicGroupMainListReducer,
    memberTechnicGroupList: memberTechnicGroupListReducer,
    statusStatisticList: statusStatisticListReducer,
    statusStatisticbyMemberList: statusStatisticbyMemberListReducer,
    allMemberListForPosition: allMemberListForPositionReducer,
    domainMainGroupList: domainMainGroupListReducer,
    domainSubGroupList: domainSubGroupListReducer,

})

export default rootReducer;