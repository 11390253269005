const SECRET_KEY = "hh3o@6mc#C2q[O=U2%He>a[KKTVmLD";

export const hashID = (id: number): string => {
  const rawString = `${SECRET_KEY}-${id}`;
  return btoa(rawString);
};

export const verifyHash = (hash: string, id: number): boolean => {
  const rawString = `${SECRET_KEY}-${id}`;
  const decoded = atob(hash);
  return decoded === rawString;
};
