
import { ActionCreator } from 'redux';
import {
  GET_MEMBER_ALL_LİST_ERROR, GET_MEMBER_ALL_LİST_SUCCESS, GET_MEMBER_ERROR, GET_MEMBER_SUCCESS,
  GET_MY_USER_DETAIL_ERROR, GET_MY_USER_DETAIL_SUCCESS, GET_SEARCHED_PERSONEL_DETAIL_SUCCESS,Member, memberActions, AllPersonnel, GET_ALL_PERSONNEL_LİST_ERROR,
  GET_ALL_PERSONNEL_LİST_SUCCESS, AllCriteria, GET_ALL_PERFORMANCE_CRİTERİA_ERROR, GET_ALL_PERFORMANCE_CRİTERİA_SUCCESS,
  Companies, GET_COMPANIES_ERROR, GET_COMPANIES_SUCCESS, Customer, GET_CUSTOMER_LİST_BY_COMPANYID_ERROR, GET_CUSTOMER_LİST_BY_COMPANYID_SUCCESS,
  Team, GET_TEAM_LİST_BY_CUSTOMER_SUCCESS, GET_TEAM_LİST_BY_CUSTOMER_ERROR, GET_MANAGER_BY_CUSTOMERID_ERROR, GET_MANAGER_BY_CUSTOMERID_SUCCESS,
  Manager, GET_EMPLOYEE_BY_INPUT_SUCCESS, GET_EMPLOYEE_BY_INPUT_ERROR, Employee, Performance, GET_LİST_PERFORMANCE_EVULATİON_ERROR,
  GET_LİST_PERFORMANCE_EVULATİON_SUCCESS, PerformanceDetail, GET_PERFORMANCE_DETAİL_ERROR, GET_PERFORMANCE_DETAİL_SUCCESS, BirthdayList, GET_BIRTHDAY_LIST_SUCCESS,
  GET_ALL_CANDIDATE_LİST_SUCCESS, GET_ALL_CANDIDATE_LİST_ERROR, CandidateList, TechnicalList, GET_ALL_TECHNICAL_GROUP_SUCCESS, GET_ALL_TECHNICAL_GROUP_ERROR, TechnicalGroupSalaryExpectationList,
  GET_TECHNICAL_GROUP_SALARY_EXPECTATION_SUCCESS, GET_YEARS_OF_EXPERIENCE_SALARY_EXPECTATION_SUCCESS, GET_COLLAGE_LİST_ERROR, GET_COLLAGE_LİST_SUCCESS, CollageList, GET_COLLAGE_DEPARTMENT_LİST_ERROR,
  GET_COLLAGE_DEPARTMENT_LİST_SUCCESS, CollageDepartmentList, GET_TECHNICAL_GROUP_SALARY_EXPECTATION_ERROR, YearsOfExperienceSalaryExpectationList, GET_YEARS_OF_EXPERIENCE_SALARY_EXPECTATION_ERROR, MemberProjectList,
  GET_MEMBER_PROJECT_LİST_ERROR, GET_MEMBER_PROJECT_LİST_SUCCESS, MemberCourseList, GET_MEMBER_COURSE_LİST_SUCCESS, GET_MEMBER_COURSE_LİST_ERROR, MemberTechnicList, GET_MEMBER_TECHNİC_LİST_SUCCESS, GET_MEMBER_TECHNİC_LİST_ERROR,
  PersonalAbilityList, GET_PERSONAL_ABİLİTY_LİST_SUCCESS, GET_PERSONAL_ABİLİTY_LİST_ERROR, MemberCollageList, MemberBadPersonalAbilityList, MemberGoodPersonalAbilityList, GET_MEMBER_BAD_PERSONAL_ABİLİTY_LİST_ERROR,
  GET_MEMBER_BAD_PERSONAL_ABİLİTY_LİST_SUCCESS, GET_MEMBER_COLLAGE_LİST_SUCCESS, GET_MEMBER_COLLAGE_LİST_ERROR, GET_MEMBER_GOOD_PERSONAL_ABİLİTY_LİST_SUCCESS, GET_MEMBER_GOOD_PERSONAL_ABİLİTY_LİST_ERROR, GET_LANGUAGE_LİST_SUCCESS,
  GET_LANGUAGE_LİST_ERROR, MemberLanguageList, GET_MEMBER_LANGUAGE_LİST_SUCCESS, GET_MEMBER_LANGUAGE_LİST_ERROR, ByTrendOverTimeList, GET_ALL_TREND_OVER_TIME_SUCCESS, GET_ALL_TREND_OVER_TIME_ERROR, PositionList, GET_POSITION_LIST_SUCCESS,
  GET_POSITION_LIST_ERROR, PositionListByCompany, GET_POSITION_LIST_BY_COMPANY_SUCCESS, GET_POSITION_LIST_BY_COMPANY_ERROR, GET_POSITION_LIST_BY_PROJECT_SUCCESS, GET_POSITION_LIST_BY_PROJECT_ERROR, PositionListByProject, CustomerList, GET_ALL_CUSTOMER_SUCCESS,
  GET_ALL_CUSTOMER_ERROR, GET_MEMBER_DOMAİN_LİST_SUCCESS, GET_MEMBER_DOMAİN_LİST_ERROR, MemberDomainList, AllSubStatusList, GET_ALL_SUB_STATUS_LİST_SUCCESS, GET_ALL_SUB_STATUS_LİST_ERROR, GET_MEMBER_TECHNİCAL_DETAİL_SUCCESS, GET_MEMBER_TECHNİCAL_DETAİL_ERROR, MemberTechnicDetailList,
  PositionListTechnicGroup, GET_POSITION_LIST_BY_TECHNIC_GROUP_SUCCESS, InterviwerList, GET_ALL_INTERVIWER_LİST_SUCCESS, GET_ALL_INTERVIWER_LİST_ERROR, GET_TECHNIC_GROUP_NUMBER_OF_PEOPLE_SUCCESS, GET_TECHNIC_GROUP_NUMBER_OF_PEOPLE_ERROR, TechnicGroupNumberOfPeopleList,
  GET_TECHNIC_GROUP_REPORT_SUCCESS, GET_TECHNIC_GROUP_REPORT_ERROR, TechnicGroupReportList, TechnicalSelectTreeList, GET_TECHNICICAL_SELECT_TREE_LIST_SUCCESS, GET_TECHNICICAL_SELECT_TREE_LIST_ERROR, MainTechnologiesList, GET_MAIN_TECHNOLOGIES_LIST_SUCCESS, GET_MAIN_TECHNOLOGIES_LIST_ERROR, GET_SUB_TECHNOLOGIES_LIST_SUCCESS, GET_SUB_TECHNOLOGIES_LIST_ERROR, SubTechnologiesList,
  GET_DOMAİN_LİST_SUCCESS, GET_DOMAİN_LİST_ERROR, DomainList, GET_PERSONAL_COMMUNİCATİON_LEVEL_LİST_SUCCESS, GET_PERSONAL_COMMUNİCATİON_LEVEL_LİST_ERROR, PersonalCommunicationLevelList, GET_POSITION_LIST_BY_TECHNIC_GROUP_ERROR, GET_MEMBER_PERSONAL_SKİLL_LİST_ERROR, GET_MEMBER_PERSONAL_SKİLL_LİST_SUCCESS, MemberPersonalSkillList,
  GET_SUB_TECHNOLOGIES_LİST_BY_MAİN_GROUP_SUCCESS, GET_SUB_TECHNOLOGIES_LİST_BY_MAİN_GROUP_ERROR, SubTechnologiesListbyMainGroup, TechnicGroupMainList, GET_TECHNİC_GROUP_MAİN_LİST_ERROR, GET_TECHNİC_GROUP_MAİN_LİST_SUCCESS, GET_MEMBER_TECHNİC_GROUP_LİST_ERROR, GET_MEMBER_TECHNİC_GROUP_LİST_SUCCESS, MemberTechnicGroupList, StatusStatisticList, GET_STATUS_STATİSTİC_LİST_SUCCESS, GET_STATUS_STATİSTİC_LİST_ERROR,
  StatusStatisticbyMemberList, GET_STATUS_STATİSTİC_BY_MEMBER_LİST_ERROR, GET_STATUS_STATİSTİC_BY_MEMBER_LİST_SUCCESS, AllMemberListForPosition, GET_ALL_MEMBER_LİST_FOR_POSİTİON_SUCCESS, GET_ALL_MEMBER_LİST_FOR_POSİTİON_ERROR,
  GET_LANGUAGE_LEVEL_LİST_SUCCESS, GET_LANGUAGE_LEVEL_LİST_ERROR, LanguageList, LanguageLevelList, GET_DOMAİN_MAİN_GROUP_LİST_SUCCESS, GET_DOMAİN_MAİN_GROUP_LİST_ERROR, GET_DOMAİN_SUB_GROUP_LİST_SUCCESS, GET_DOMAİN_SUB_GROUP_LİST_ERROR, DomainMainGroupList, DomainSubGroupList
} from '../../redux/actions/actionTypes';
import axios, { AxiosError } from 'axios';
import { SERVICEURL } from '../../appconfig';
import { lazy } from 'react';
const Login = lazy(() => import("../../pages/login/Login"));

//Başarılıysa reducer'a GET_TASK_LIST_SUCCESS action'un ve parametresini döndürüyoruz.
const getMemberListSuccess: ActionCreator<memberActions> = (list: Member[]) => {
  return { type: GET_MEMBER_SUCCESS, payload: list };
};
const getMemberListError: ActionCreator<memberActions> = (message: string) => {
  return { type: GET_MEMBER_ERROR, payload: message };
};

const getMemberAllListSuccess: ActionCreator<memberActions> = (list: Member[]) => {
  return { type: GET_MEMBER_ALL_LİST_SUCCESS, payload: list };
}

const getMemberAllListError: ActionCreator<memberActions> = (message: string) => {
  return { type: GET_MEMBER_ALL_LİST_ERROR, payload: message };
}

const getMyUserDetailListSuccess: ActionCreator<memberActions> = (list: Member[]) => {
  return { type: GET_MY_USER_DETAIL_SUCCESS, payload: list };
}
const getSearchedPersonelDetailListSuccess: ActionCreator<memberActions> = (list: Member[]) => {
  return { type: GET_SEARCHED_PERSONEL_DETAIL_SUCCESS, payload: list };
}

const getMyUserDetailListError: ActionCreator<memberActions> = (message: string) => {
  return { type: GET_MY_USER_DETAIL_ERROR, payload: message };
}

const getAllPersonnelListSuccess: ActionCreator<memberActions> = (list: AllPersonnel[]) => {
  return { type: GET_ALL_PERSONNEL_LİST_SUCCESS, payload: list };
}

const getAllPersonnelListError: ActionCreator<memberActions> = (message: string) => {
  return { type: GET_ALL_PERSONNEL_LİST_ERROR, payload: message };
}
const getAllPerformanceCriteriaSuccess: ActionCreator<memberActions> = (list: AllCriteria[]) => {
  return { type: GET_ALL_PERFORMANCE_CRİTERİA_SUCCESS, payload: list };
};
const getAllPerformanceCriteriaError: ActionCreator<memberActions> = (message: string) => {
  return { type: GET_ALL_PERFORMANCE_CRİTERİA_ERROR, payload: message };
};
const getCompaniesListSuccess: ActionCreator<memberActions> = (list: Companies[]) => {
  return { type: GET_COMPANIES_SUCCESS, payload: list };
}

const getCompaniesListError: ActionCreator<memberActions> = (message: string) => {
  return { type: GET_COMPANIES_ERROR, payload: message };
}
const getCustomerListByCompanyIdSuccess: ActionCreator<memberActions> = (list: Customer[]) => {
  return { type: GET_CUSTOMER_LİST_BY_COMPANYID_SUCCESS, payload: list };
}
const getCustomerListByCompanyIdError: ActionCreator<memberActions> = (message: string) => {
  return { type: GET_CUSTOMER_LİST_BY_COMPANYID_ERROR, payload: message };
}
const getTeamListByCompanySuccess: ActionCreator<memberActions> = (list: Team[]) => {
  return { type: GET_TEAM_LİST_BY_CUSTOMER_SUCCESS, payload: list };
}
const getTeamListByCompanyError: ActionCreator<memberActions> = (message: string) => {
  return { type: GET_TEAM_LİST_BY_CUSTOMER_ERROR, payload: message };
}
const getManagerByCustomerIdSuccess: ActionCreator<memberActions> = (list: Manager[]) => {
  return { type: GET_MANAGER_BY_CUSTOMERID_SUCCESS, payload: list };
}
const getManagerByCustomerIdError: ActionCreator<memberActions> = (message: string) => {
  return { type: GET_MANAGER_BY_CUSTOMERID_ERROR, payload: message };
}
const getEmployeeByInputSuccess: ActionCreator<memberActions> = (list: Employee[]) => {
  return { type: GET_EMPLOYEE_BY_INPUT_SUCCESS, payload: list };
}
const getEmployeeByInputError: ActionCreator<memberActions> = (message: string) => {
  return { type: GET_MANAGER_BY_CUSTOMERID_ERROR, payload: message };
}
const getListPerformanceEvulationSuccess: ActionCreator<memberActions> = (list: Performance[]) => {
  return { type: GET_LİST_PERFORMANCE_EVULATİON_SUCCESS, payload: list };
}
const getListPerformanceEvulationError: ActionCreator<memberActions> = (message: string) => {
  return { type: GET_LİST_PERFORMANCE_EVULATİON_ERROR, payload: message };
}
const getPerformanceDetailSuccess: ActionCreator<memberActions> = (list: PerformanceDetail[]) => {
  return { type: GET_PERFORMANCE_DETAİL_SUCCESS, payload: list };
}
const getPerformanceDetailError: ActionCreator<memberActions> = (message: string) => {
  return { type: GET_PERFORMANCE_DETAİL_ERROR, payload: message };
}
const getBirthdaySuccess: ActionCreator<memberActions> = (list: BirthdayList[]) => {
  return { type: GET_BIRTHDAY_LIST_SUCCESS, payload: list };
}
const getBirthdayError: ActionCreator<memberActions> = (message: string) => {
  return { type: GET_ALL_PERFORMANCE_CRİTERİA_ERROR, payload: message }
}
const getAllCandidateListSuccess: ActionCreator<memberActions> = (list: CandidateList[]) => {
  return { type: GET_ALL_CANDIDATE_LİST_SUCCESS, payload: list };
}

const getAllCandidateListError: ActionCreator<memberActions> = (message: string) => {
  return { type: GET_ALL_CANDIDATE_LİST_ERROR, payload: message };
}

const getAllTechnicalGroupSuccess: ActionCreator<memberActions> = (list: TechnicalList[]) => {
  return { type: GET_ALL_TECHNICAL_GROUP_SUCCESS, payload: list };
}

const getAllTechnicalGroupError: ActionCreator<memberActions> = (message: string) => {
  return { type: GET_ALL_TECHNICAL_GROUP_ERROR, payload: message };
}

const getTechnicalGroupSalaryExpectationSuccess: ActionCreator<memberActions> = (list: TechnicalGroupSalaryExpectationList[]) => {
  return { type: GET_TECHNICAL_GROUP_SALARY_EXPECTATION_SUCCESS, payload: list };
}

const getTechnicalGroupSalaryExpectationError: ActionCreator<memberActions> = (message: string) => {
  return { type: GET_TECHNICAL_GROUP_SALARY_EXPECTATION_ERROR, payload: message };
}

const getYearsOfExperienceSalaryExpectationSuccess: ActionCreator<memberActions> = (list: YearsOfExperienceSalaryExpectationList[]) => {
  return { type: GET_YEARS_OF_EXPERIENCE_SALARY_EXPECTATION_SUCCESS, payload: list };
}

const getYearsOfExperienceSalaryExpectationError: ActionCreator<memberActions> = (message: string) => {
  return { type: GET_YEARS_OF_EXPERIENCE_SALARY_EXPECTATION_ERROR, payload: message };
}

const getCollageListSuccess: ActionCreator<memberActions> = (list: CollageList[]) => {
  return { type: GET_COLLAGE_LİST_SUCCESS, payload: list };
}

const getCollageListError: ActionCreator<memberActions> = (message: string) => {
  return { type: GET_COLLAGE_LİST_ERROR, payload: message };
}

const getCollageDepartmentListSuccess: ActionCreator<memberActions> = (list: CollageDepartmentList[]) => {
  return { type: GET_COLLAGE_DEPARTMENT_LİST_SUCCESS, payload: list };
}

const getCollageDepartmentListError: ActionCreator<memberActions> = (message: string) => {
  return { type: GET_COLLAGE_DEPARTMENT_LİST_ERROR, payload: message };
}

const getMemberProjectListSuccess: ActionCreator<memberActions> = (list: MemberProjectList[]) => {
  return { type: GET_MEMBER_PROJECT_LİST_SUCCESS, payload: list };
}

const getMemberProjectListError: ActionCreator<memberActions> = (message: string) => {
  return { type: GET_MEMBER_PROJECT_LİST_ERROR, payload: message };
}

const getMemberCourseListSuccess: ActionCreator<memberActions> = (list: MemberCourseList[]) => {
  return { type: GET_MEMBER_COURSE_LİST_SUCCESS, payload: list };
}

const getMemberCourseListError: ActionCreator<memberActions> = (message: string) => {
  return { type: GET_MEMBER_COURSE_LİST_ERROR, payload: message };
}

const getMemberTechnicListSuccess: ActionCreator<memberActions> = (list: MemberTechnicList[]) => {
  return { type: GET_MEMBER_TECHNİC_LİST_SUCCESS, payload: list };
}

const getMemberTechnicListError: ActionCreator<memberActions> = (message: string) => {
  return { type: GET_MEMBER_TECHNİC_LİST_ERROR, payload: message };
}

const getPersonalAbilitiyListSuccess: ActionCreator<memberActions> = (list: PersonalAbilityList[]) => {
  return { type: GET_PERSONAL_ABİLİTY_LİST_SUCCESS, payload: list };
}

const getPersonalAbilitiyListError: ActionCreator<memberActions> = (message: string) => {
  return { type: GET_PERSONAL_ABİLİTY_LİST_ERROR, payload: message };
}

const getMemberGoodPersonalAbilitiyListSuccess: ActionCreator<memberActions> = (list: MemberGoodPersonalAbilityList[]) => {
  return { type: GET_MEMBER_GOOD_PERSONAL_ABİLİTY_LİST_SUCCESS, payload: list };
}

const getMemberGoodPersonalAbilitiyListError: ActionCreator<memberActions> = (message: string) => {
  return { type: GET_MEMBER_GOOD_PERSONAL_ABİLİTY_LİST_ERROR, payload: message };
}

const getMemberBadPersonalAbilitiyListSuccess: ActionCreator<memberActions> = (list: MemberBadPersonalAbilityList[]) => {
  return { type: GET_MEMBER_BAD_PERSONAL_ABİLİTY_LİST_SUCCESS, payload: list };
}

const getMemberBadPersonalAbilitiyListError: ActionCreator<memberActions> = (message: string) => {
  return { type: GET_MEMBER_BAD_PERSONAL_ABİLİTY_LİST_ERROR, payload: message };
}

const getMemberCollageListSuccess: ActionCreator<memberActions> = (list: MemberCollageList[]) => {
  return { type: GET_MEMBER_COLLAGE_LİST_SUCCESS, payload: list };
}

const getMemberCollageListError: ActionCreator<memberActions> = (message: string) => {
  return { type: GET_MEMBER_COLLAGE_LİST_ERROR, payload: message };
}

const getMemberLanguageListSuccess: ActionCreator<memberActions> = (list: MemberLanguageList[]) => {
  return { type: GET_MEMBER_LANGUAGE_LİST_SUCCESS, payload: list };
}

const getMemberLanguageListError: ActionCreator<memberActions> = (message: string) => {
  return { type: GET_MEMBER_LANGUAGE_LİST_ERROR, payload: message };
}

const getByTrendOverTimeSuccess: ActionCreator<memberActions> = (list: ByTrendOverTimeList[]) => {
  return { type: GET_ALL_TREND_OVER_TIME_SUCCESS, payload: list };
}

const getByTrendOverTimeError: ActionCreator<memberActions> = (message: string) => {
  return { type: GET_ALL_TREND_OVER_TIME_ERROR, payload: message };
}

const getPositionListSuccess: ActionCreator<memberActions> = (list: PositionList[]) => {
  return { type: GET_POSITION_LIST_SUCCESS, payload: list };
}

const getPositionListError: ActionCreator<memberActions> = (message: string) => {
  return { type: GET_POSITION_LIST_ERROR, payload: message };
}

const getPositionListByCompanySuccess: ActionCreator<memberActions> = (list: PositionListByCompany[]) => {
  return { type: GET_POSITION_LIST_BY_COMPANY_SUCCESS, payload: list };
}

const getPositionListByCompanyError: ActionCreator<memberActions> = (message: string) => {
  return { type: GET_POSITION_LIST_BY_COMPANY_ERROR, payload: message };
}

const getPositionListByProjectSuccess: ActionCreator<memberActions> = (list: PositionListByProject[]) => {
  return { type: GET_POSITION_LIST_BY_PROJECT_SUCCESS, payload: list };
}

const getPositionListByProjectError: ActionCreator<memberActions> = (message: string) => {
  return { type: GET_POSITION_LIST_BY_PROJECT_ERROR, payload: message };
}

const getCustomerListSuccess: ActionCreator<memberActions> = (list: CustomerList[]) => {
  return { type: GET_ALL_CUSTOMER_SUCCESS, payload: list };
}

const getCustomerListError: ActionCreator<memberActions> = (message: string) => {
  return { type: GET_ALL_CUSTOMER_ERROR, payload: message };
}

const getMemberDomainListSuccess: ActionCreator<memberActions> = (list: MemberDomainList[]) => {
  return { type: GET_MEMBER_DOMAİN_LİST_SUCCESS, payload: list };
}

const getMemberDomainListError: ActionCreator<memberActions> = (message: string) => {
  return { type: GET_MEMBER_DOMAİN_LİST_ERROR, payload: message };
}

const getAllSubStatusListSuccess: ActionCreator<memberActions> = (list: AllSubStatusList[]) => {
  return { type: GET_ALL_SUB_STATUS_LİST_SUCCESS, payload: list };
}

const getAllSubStatusListError: ActionCreator<memberActions> = (message: string) => {
  return { type: GET_ALL_SUB_STATUS_LİST_ERROR, payload: message };
}

const getAllInterviwerListSuccess: ActionCreator<memberActions> = (list: InterviwerList[]) => {
  return { type: GET_ALL_INTERVIWER_LİST_SUCCESS, payload: list };
}

const getAllInterviwerListError: ActionCreator<memberActions> = (message: string) => {
  return { type: GET_ALL_INTERVIWER_LİST_ERROR, payload: message };
}


const getMemberTechnicDetailSuccess: ActionCreator<memberActions> = (list: MemberTechnicDetailList[]) => {
  return { type: GET_MEMBER_TECHNİCAL_DETAİL_SUCCESS, payload: list };
}

const getMemberTechnicDetailError: ActionCreator<memberActions> = (message: string) => {
  return { type: GET_MEMBER_TECHNİCAL_DETAİL_ERROR, payload: message };
}

const getPositionListTechnicGroupSuccess: ActionCreator<memberActions> = (list: PositionListTechnicGroup[]) => {
  return { type: GET_POSITION_LIST_BY_TECHNIC_GROUP_SUCCESS, payload: list };
}

const getPositionListTechnicGroupError: ActionCreator<memberActions> = (message: string) => {
  return { type: GET_POSITION_LIST_BY_TECHNIC_GROUP_ERROR, payload: message };
}

const getTechnicGroupNumberOfPeopleSuccess: ActionCreator<memberActions> = (list: TechnicGroupNumberOfPeopleList[]) => {
  return { type: GET_TECHNIC_GROUP_NUMBER_OF_PEOPLE_SUCCESS, payload: list };
}

const getTechnicGroupNumberOfPeopleError: ActionCreator<memberActions> = (message: string) => {
  return { type: GET_TECHNIC_GROUP_NUMBER_OF_PEOPLE_ERROR, payload: message };
}

const getTechnicGroupReportSuccess: ActionCreator<memberActions> = (list: TechnicGroupReportList[]) => {
  return { type: GET_TECHNIC_GROUP_REPORT_SUCCESS, payload: list };
}

const getTechnicGroupReportError: ActionCreator<memberActions> = (message: string) => {
  return { type: GET_TECHNIC_GROUP_REPORT_ERROR, payload: message };
}
const getTechnicalSelectTreeListSuccess: ActionCreator<memberActions> = (list: TechnicalSelectTreeList[]) => {
  return { type: GET_TECHNICICAL_SELECT_TREE_LIST_SUCCESS, payload: list };
}

const getTechnicalSelectTreeListError: ActionCreator<memberActions> = (message: string) => {
  return { type: GET_TECHNICICAL_SELECT_TREE_LIST_ERROR, payload: message };
}

const getMainTechnologiesListSuccess: ActionCreator<memberActions> = (list: MainTechnologiesList[]) => {
  return { type: GET_MAIN_TECHNOLOGIES_LIST_SUCCESS, payload: list };
}

const getMainTechnologiesListError: ActionCreator<memberActions> = (message: string) => {
  return { type: GET_MAIN_TECHNOLOGIES_LIST_ERROR, payload: message };
}

const getSubTechnologiesListSuccess: ActionCreator<memberActions> = (list: SubTechnologiesList[]) => {
  return { type: GET_SUB_TECHNOLOGIES_LIST_SUCCESS, payload: list };
}

const getSubTechnologiesListError: ActionCreator<memberActions> = (message: string) => {
  return { type: GET_SUB_TECHNOLOGIES_LIST_ERROR, payload: message };
}

const getDomainListSuccess: ActionCreator<memberActions> = (list: DomainList[]) => {
  return { type: GET_DOMAİN_LİST_SUCCESS, payload: list };
}

const getDomainListError: ActionCreator<memberActions> = (message: string) => {
  return { type: GET_DOMAİN_LİST_ERROR, payload: message };
}
//
const getLanguageListSuccess: ActionCreator<memberActions> = (list: LanguageList[]) => {
  return { type: GET_LANGUAGE_LİST_SUCCESS, payload: list };
}
const getLanguageListError: ActionCreator<memberActions> = (message: string) => {
  return { type: GET_LANGUAGE_LİST_ERROR, payload: message };
}

const getLanguageLevelListSuccess: ActionCreator<memberActions> = (list: LanguageLevelList[]) => {
  return { type: GET_LANGUAGE_LEVEL_LİST_SUCCESS, payload: list };
}
const getLanguageLevelListError: ActionCreator<memberActions> = (message: string) => {
  return { type: GET_LANGUAGE_LEVEL_LİST_ERROR, payload: message };
}

const getPersonalCommunicationLevelListSuccess: ActionCreator<memberActions> = (list: PersonalCommunicationLevelList[]) => {
  return { type: GET_PERSONAL_COMMUNİCATİON_LEVEL_LİST_SUCCESS, payload: list };
}

const getPersonalCommunicationLevelListError: ActionCreator<memberActions> = (message: string) => {
  return { type: GET_PERSONAL_COMMUNİCATİON_LEVEL_LİST_ERROR, payload: message };
}

const getMemberPersonalSkillListSuccess: ActionCreator<memberActions> = (list: MemberPersonalSkillList[]) => {
  return { type: GET_MEMBER_PERSONAL_SKİLL_LİST_SUCCESS, payload: list };
}

const getMemberPersonalSkillListError: ActionCreator<memberActions> = (message: string) => {
  return { type: GET_MEMBER_PERSONAL_SKİLL_LİST_ERROR, payload: message };
}

const getSubTechnologiesListByMainGroupSuccess: ActionCreator<memberActions> = (list: SubTechnologiesListbyMainGroup[]) => {
  return { type: GET_SUB_TECHNOLOGIES_LİST_BY_MAİN_GROUP_SUCCESS, payload: list };
}

const getSubTechnologiesListByMainGroupError: ActionCreator<memberActions> = (message: string) => {
  return { type: GET_SUB_TECHNOLOGIES_LİST_BY_MAİN_GROUP_ERROR, payload: message };
}

const getTechnicGroupMainListSuccess: ActionCreator<memberActions> = (list: TechnicGroupMainList[]) => {
  return { type: GET_TECHNİC_GROUP_MAİN_LİST_SUCCESS, payload: list };
}

const getTechnicGroupMainListError: ActionCreator<memberActions> = (message: string) => {
  return { type: GET_TECHNİC_GROUP_MAİN_LİST_ERROR, payload: message };
}

const getMemberTechnicGroupListSuccess: ActionCreator<memberActions> = (list: MemberTechnicGroupList[]) => {
  return { type: GET_MEMBER_TECHNİC_GROUP_LİST_SUCCESS, payload: list };
}

const getMemberTechnicGroupListError: ActionCreator<memberActions> = (message: string) => {
  return { type: GET_MEMBER_TECHNİC_GROUP_LİST_ERROR, payload: message };
}

const getStatusStatisticListSuccess: ActionCreator<memberActions> = (list: StatusStatisticList[]) => {
  return { type: GET_STATUS_STATİSTİC_LİST_SUCCESS, payload: list };
}

const getStatusStatisticListError: ActionCreator<memberActions> = (message: string) => {
  return { type: GET_STATUS_STATİSTİC_LİST_ERROR, payload: message };
}

const getStatusStatisticbyMemberListSuccess: ActionCreator<memberActions> = (list: StatusStatisticbyMemberList[]) => {
  return { type: GET_STATUS_STATİSTİC_BY_MEMBER_LİST_SUCCESS, payload: list };
}

const getStatusStatisticbyMemberListError: ActionCreator<memberActions> = (message: string) => {
  return { type: GET_STATUS_STATİSTİC_BY_MEMBER_LİST_ERROR, payload: message };
}

const getAllMemberListForPositionSuccess: ActionCreator<memberActions> = (list: AllMemberListForPosition[]) => {
  return { type: GET_ALL_MEMBER_LİST_FOR_POSİTİON_SUCCESS, payload: list };
}

const getAllMemberListForPositionError: ActionCreator<memberActions> = (message: string) => {
  return { type: GET_ALL_MEMBER_LİST_FOR_POSİTİON_ERROR, payload: message };
}
const getDomainMainGroupListSuccess: ActionCreator<memberActions> = (list: DomainMainGroupList[]) => {
  return { type: GET_DOMAİN_MAİN_GROUP_LİST_SUCCESS, payload: list };
}

const getDomainMainGroupListError: ActionCreator<memberActions> = (message: string) => {
  return { type: GET_DOMAİN_MAİN_GROUP_LİST_ERROR, payload: message };
}

const getDomainSubGroupListSuccess: ActionCreator<memberActions> = (list: DomainSubGroupList[]) => {
  return { type: GET_DOMAİN_SUB_GROUP_LİST_SUCCESS, payload: list };
}

const getDomainSubGroupListError: ActionCreator<memberActions> = (message: string) => {
  return { type: GET_DOMAİN_SUB_GROUP_LİST_ERROR, payload: message };
}

const mytoken = localStorage.getItem('token')
const HEADER = {
  headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + mytoken }
}

export function getMemberList(state: { state: { userName: string; }; }) {
  const userName = state.state.userName.toString();
   return (dispatch: (arg0: memberActions) => void) => {

    if (userName != null) {
      const input = { Name: userName.toString() }
      axios.post(SERVICEURL + 'api/Membership/GetMemberListBoard', input, HEADER)
        .then(response => {
          dispatch(getMemberListSuccess(response));
        })
        .catch(error => {
          dispatch(getMemberListError("Serve error."))
        })
    }
  };
}

export function getMemberAllList(state: { state: { userName: string; }; }) {
  const userName = state.state.userName.toString();
   return (dispatch: (arg0: memberActions) => void) => {

    if (userName != null) {
      const input = { Name: userName.toString() }
      axios.post(SERVICEURL + 'api/Membership/GetAllMemberList', input, HEADER)
        .then(response => {
          dispatch(getMemberAllListSuccess(response));
        })
        .catch(error => {
          dispatch(getMemberAllListError("Save error."))
        });
    }
  }
}

export function getMyUserDetailList(state: { state: { memberId: number; }; }) {
  const id = state.state.memberId;
  return (dispatch: (arg0: memberActions) => void) => {
    if (id != null) {
      const input = { MemberId: id }
      axios.post(SERVICEURL + 'api/Membership/GetMyDetail?', input, HEADER)
        .then(response => {
          dispatch(getMyUserDetailListSuccess(response));
        })
        .catch((reason: AxiosError) => {
          if (reason.response!.status === 401) {
            localStorage.removeItem('token');
            window.location.reload();
            <Login />
          } else {
            dispatch(getMyUserDetailListError("Save error."))
            console.log("Handle else:", reason.message);
          }
        })
    }
  }
}
export function getSearchedPersonelDetailList(state: { state: { memberId: number; }; }) {
  const id = state.state.memberId;
  return (dispatch: (arg0: memberActions) => void) => {
    if (id != null) {
      const input = { MemberId: id }
      axios.post(SERVICEURL + 'api/Membership/GetMyDetail?', input, HEADER)
        .then(response => {
          dispatch(getSearchedPersonelDetailListSuccess(response));
        })
        .catch((reason: AxiosError) => {
          if (reason.response!.status === 401) {
            localStorage.removeItem('token');
            window.location.reload();
            <Login />
          } else {
            dispatch(getMyUserDetailListError("Save error."))
            console.log("Handle else:", reason.message);
          }
        })
    }
  }
}
export function getPersonelAllList(state: { technicId: number; value: number[]; techvalue: number[]; state: { name: string; phoneNo: string; email: string; englishLevelId: number; experienceYearEnd: number; experienceYearStart: number; salaryEndValue: number; salaryFirstValue: number; features: number[]; domainId: number; }; }) {
  const name = state.state.name;
  const phoneNo = state.state.phoneNo.replaceAll('_', '').trimRight();
  const email = state.state.email;
  const techGroup = state.techvalue;
  const englishLevelId = state.state.englishLevelId;
  const experienceYearEnd = state.state.experienceYearEnd;
  const experienceYearStart = state.state.experienceYearStart;
  const salaryEndValue = state.state.salaryEndValue;
  const salaryFirstValue = state.state.salaryFirstValue;
  const technicId = state.technicId;
  const features = state.value;
  const domainId = state.state.domainId;

  return (dispatch: (arg0: memberActions) => void) => {

    if (name != null || phoneNo != null || email != null || techGroup != null || englishLevelId != null || experienceYearEnd != null || experienceYearStart != null || features != null || domainId != null) {
      const input = { name: name, phoneNo: phoneNo, email: email, techGroupId: techGroup, languageId: englishLevelId, endingTotalExperienceYear: experienceYearEnd, startingTotalExperienceYear: experienceYearStart, endingSalaryExpectation: salaryEndValue, startingSalaryExpectation: salaryFirstValue, technicalSkillId: technicId, features: features, domainId: domainId }
       axios.post(SERVICEURL + 'api/Membership/GetAllMemberList', input, HEADER)
        .then(response => {
          dispatch(getAllPersonnelListSuccess(response));
        })
        .catch(error => {
          dispatch(getAllPersonnelListError("Save error."))
        });
    }
  }
}

export function getAllPerformanceCriteriaList(state: { state: {}; }) {

  return (dispatch: (arg0: memberActions) => void) => {


    axios.get(SERVICEURL + 'api/Performance/GetAllPerformanceCriteria?', HEADER)
      .then(response => {
        dispatch(getAllPerformanceCriteriaSuccess(response));
      })
      .catch(error => {
        dispatch(getAllPerformanceCriteriaError("Save error."))
      });

  }
}

export function getCompaniesList() {
  // const name = state.state.name;
  return (dispatch: (arg0: memberActions) => void) => {

    // if (name != null) {
    //   const input = { memberId: name }

    axios.get(SERVICEURL + 'api/Data/GetCompanies?', HEADER)
      .then(response => {
        dispatch(getCompaniesListSuccess(response));
      })
      .catch((reason: AxiosError) => {
        if (reason.response!.status === 401) {
          localStorage.removeItem('token');
          window.location.reload();
        } else {
          console.log("Handle else: ", reason.message);
        }

      });

    // }
  }
}

export function getCustomerListByCompanyId(state: { state: { companyId: number; }; }) {
  const id = state.state.companyId;
  return (dispatch: (arg0: memberActions) => void) => {

    if (id != null) {
      const input = { Id: id }
      axios.post(SERVICEURL + 'api/Data/GetCustomerListbyCompanyId', input, HEADER)
        .then(response => {
          dispatch(getCustomerListByCompanyIdSuccess(response));
        })
        .catch(error => {
          dispatch(getCustomerListByCompanyIdError("Save error."))
        });
    }
  }
}
export function getTeamListByCustomer(state: { state: { customerId: number; } }) {
  const id = state.state.customerId;
  return (dispatch: (arg0: memberActions) => void) => {

    if (id != null) {
      const input = { Id: id }
      axios.post(SERVICEURL + 'api/Data/GetManagerbyCustomerId', input, HEADER)
        .then(response => {
          dispatch(getManagerByCustomerIdSuccess(response));
        })
        .catch(error => {
          dispatch(getManagerByCustomerIdError("Save error."))
        });
    }
  }
}

export function getManagerByCustomerId(state: { state: { customerId: number; } }) {
  const id = state.state.customerId;
  return (dispatch: (arg0: memberActions) => void) => {

    if (id != null) {
      const input = { Id: id }
      axios.post(SERVICEURL + 'api/Data/GetTeamsbyCustomerId', input, HEADER)
        .then(response => {
          if (response.data)
            dispatch(getTeamListByCompanySuccess(response));
        })
        .catch(error => {
          dispatch(getTeamListByCompanyError("Save error."))
        });
    }
  }
}

export function getEmployeeByInput(state: { state: { customerId: number; teamId: number; } }) {
  const customerId = state.state.customerId;
  const teamId = state.state.teamId;
  return (dispatch: (arg0: memberActions) => void) => {

    if (customerId != null || teamId != null) {
      const input = { customerID: customerId, teamID: teamId }
      axios.post(SERVICEURL + 'api/Data/GetEmployeebyInput', input, HEADER)
        .then(response => {
          dispatch(getEmployeeByInputSuccess(response));
        })
        .catch(error => {
          dispatch(getEmployeeByInputError("Save error."))
        });
    }
  }
}

export function getListPerformanceEvulation(state: { state: { customerId: number; companyId: number; teamId: number; employeeId: number; managerId: number; period: Date | null } }) {
  const customerId = state.state.customerId;
  const companyId = state.state.companyId;
  const teamId = state.state.teamId;
  const employeeId = state.state.employeeId;
  const managerId = state.state.managerId;
  const period = state.state.period;
  return (dispatch: (arg0: memberActions) => void) => {

    if (customerId != null && companyId != null) {
      const input = { CompanyId: companyId, CustomerId: customerId, TeamId: teamId, MemberId: employeeId, AppraisalManagerId: managerId, Period: period }
      // const input = { CompanyId: 61885, CustomerId: 2, TeamId: 0, MemberId: 0, AppraisalManagerId: 0 ,Period:null}

      axios.post(SERVICEURL + 'api/Performance/ListPerformanceEvulation', input, HEADER)
        .then(response => {
          dispatch(getListPerformanceEvulationSuccess(response));
        })
        .catch(error => {
          dispatch(getListPerformanceEvulationError("Save error."))
        });
    }
  }
}

export function getPerformanceDetailList(state: { state: { id: any; }; }) {
  const id = state.state.id;
  return (dispatch: (arg0: memberActions) => void) => {

    if (id != null) {
      const input = { Id: id }
      axios.post(SERVICEURL + 'api/Performance/GetPerformanceEvulationbyId?', input, HEADER)
        .then(response => {
          dispatch(getPerformanceDetailSuccess(response));
        })
        .catch(error => {
          dispatch(getPerformanceDetailError("Save error."))
        });
    }
  }
}

export function getBirthdayList() {
  return (dispatch: (arg0: memberActions) => void) => {

    axios.get(SERVICEURL + 'api/Home/GetSpecialDays?', HEADER)
      .then(response => {
        dispatch(getBirthdaySuccess(response));
      })
      .catch(error => {
        dispatch(getBirthdayError("Save error."))
        localStorage.removeItem('token');
        window.location.reload();
      });

  }
}

export function getCandidateAllList(state: { state: {}; }) {
  return (dispatch: (arg0: memberActions) => void) => {

    axios.get(SERVICEURL + 'api/Membership/GetAllMembersList', HEADER)
      .then(response => {
        dispatch(getAllCandidateListSuccess(response));
      })
      .catch(error => {
        dispatch(getAllCandidateListError("Save error."))
      });

  }
}

export function getAllTechnicalGroupList() {
  return (dispatch: (arg0: memberActions) => void) => {

    axios.get(SERVICEURL + 'api/Data/GetTechnicalGroup?', HEADER)
      .then(response => {

        dispatch(getAllTechnicalGroupSuccess(response));
      })
      .catch(error => {

        console.log(error)

        dispatch(getAllTechnicalGroupError("Save error."))
      });
  }
}

export function getTechnicalGroupSalaryExpectationList(state: { techvalue: number[]; salaryUnit: { domestic: boolean, abroad: boolean }, state: { /*technicalId: any;*/ dateStart: Date | null; dateEnd: Date | null; selectYear: Date | null; monthRangeStart: any; monthRangeEnd: any }; }) {
  //const id = state.state.technicalId;
  const techGroup = state.techvalue;
  const dateStart = state.state.dateStart;
  const dateEnd = state.state.dateEnd;
  const selectYear = state.state.selectYear;
  const monthRangeStart = state.state.monthRangeStart;
  const monthRangeEnd = state.state.monthRangeEnd;
  const domestic = state.salaryUnit.domestic;
  const abroad = state.salaryUnit.abroad;

  return (dispatch: (arg0: memberActions) => void) => {

    if (techGroup != null) {
      const input = { TechGroupId: techGroup, SelectedYear: selectYear, StartMonth: monthRangeStart, EndMonth: monthRangeEnd, AbroadSalary: abroad, DomesticSalary: domestic, PeriodStartDate: dateStart, PeriodEndDate: dateEnd }
      axios.post(SERVICEURL + 'api/Report/ListByTechnicalGroupSalaryExpectation?', input, HEADER)
        .then(response => {
          dispatch(getTechnicalGroupSalaryExpectationSuccess(response));
        })
        .catch(error => {
          dispatch(getTechnicalGroupSalaryExpectationError("Save error."))
        });
    }
  }
}

export function getYearsOfExperienceSalaryExpectationList(state: { salaryUnit: { domestic: boolean, abroad: boolean }, state: { dateStart: Date | null; dateEnd: Date | null; experienceYearStart: any; experienceYearEnd: any; selectYear: Date | null; monthRangeStart: any; monthRangeEnd: any }; }) {
  const dateStart = state.state.dateStart;
  const dateEnd = state.state.dateEnd;
  const selectYear = state.state.selectYear;
  const monthRangeStart = state.state.monthRangeStart;
  const monthRangeEnd = state.state.monthRangeEnd;
  const experienceYearStart = state.state.experienceYearStart;
  const setExperienceYearEnd = state.state.experienceYearEnd;
  const domestic = state.salaryUnit.domestic;
  const abroad = state.salaryUnit.abroad;
  return (dispatch: (arg0: memberActions) => void) => {

    if (experienceYearStart != null) {
      const input = { PeriodStartDate: dateStart, PeriodEndDate: dateEnd, SelectedYear: selectYear, StartMonth: monthRangeStart, EndMonth: monthRangeEnd, AbroadSalary: abroad, DomesticSalary: domestic, ExperienceYearStart: experienceYearStart, ExperienceYearEnd: setExperienceYearEnd }
      axios.post(SERVICEURL + 'api/Report/ListByYearsOfExperienceSalaryExpectation?', input, HEADER)
        .then(response => {
          dispatch(getYearsOfExperienceSalaryExpectationSuccess(response));
        })
        .catch(error => {
          dispatch(getYearsOfExperienceSalaryExpectationError("Save error."))
        });
    }
  }
}

export function getByTrendOverTimeList(state: { techvalue: number[]; salaryUnit: { domestic: boolean, abroad: boolean }, state: { /*technicalId: any;*/ dateStart: Date | null; dateEnd: Date | null; experienceYearStart: any; experienceYearEnd: any; selectYear: Date | null; monthRangeStart: any; monthRangeEnd: any }; }) {
  const dateStart = state.state.dateStart;
  const dateEnd = state.state.dateEnd;
  const selectYear = state.state.selectYear;
  const monthRangeStart = state.state.monthRangeStart;
  const monthRangeEnd = state.state.monthRangeEnd;
  const experienceYearStart = state.state.experienceYearStart;
  const setExperienceYearEnd = state.state.experienceYearEnd;
  //const technicalId = state.state.technicalId;
  const techGroup = state.techvalue;
  const domestic = state.salaryUnit.domestic;
  const abroad = state.salaryUnit.abroad;
  return (dispatch: (arg0: memberActions) => void) => {

    if (experienceYearStart != null) {
      const input = { TechGroupId: techGroup, PeriodStartDate: dateStart, PeriodEndDate: dateEnd, SelectedYear: selectYear, StartMonth: monthRangeStart, EndMonth: monthRangeEnd, AbroadSalary: abroad, DomesticSalary: domestic, ExperienceYearStart: experienceYearStart, ExperienceYearEnd: setExperienceYearEnd }
      axios.post(SERVICEURL + 'api/Report/ListByTrendOverTime?', input, HEADER)
        .then(response => {
          dispatch(getByTrendOverTimeSuccess(response));
        })
        .catch(error => {
          dispatch(getByTrendOverTimeError("Save error."))
        });
    }
  }
}

export function getCollageList() {
  return (dispatch: (arg0: memberActions) => void) => {

    axios.get(SERVICEURL + 'api/Data/GetCollageList?', HEADER)
      .then(response => {
        dispatch(getCollageListSuccess(response));
      })
      .catch(error => {
        dispatch(getCollageListError("Save error."))
      });

  }
}

export function getCollageDepartmentList() {
  return (dispatch: (arg0: memberActions) => void) => {

    axios.get(SERVICEURL + 'api/Data/GetCollageDepartmentList?', HEADER)
      .then(response => {
        dispatch(getCollageDepartmentListSuccess(response));
      })
      .catch(error => {
        dispatch(getCollageDepartmentListError("Save error."))
      });

  }
}

export function getMemberProjectList(state: { state: { memberId: any; }; }) {
  const id = state.state.memberId;
  return (dispatch: (arg0: memberActions) => void) => {
    if (id != null) {
      const input = { memberId: id }
      axios.post(SERVICEURL + 'api/Personnel/GetAwardProjectInfoList?', input, HEADER)
        .then(response => {
          dispatch(getMemberProjectListSuccess(response));
        })
        .catch(error => {
          dispatch(getMemberProjectListError("Save error."))
        });
    }
  }
}

export function getMemberCourseList(state: { state: { memberId: any; }; }) {
  const id = state.state.memberId;
  return (dispatch: (arg0: memberActions) => void) => {
    if (id != null) {
      const input = { memberId: id }
      axios.post(SERVICEURL + 'api/Personnel/GetCertificateList?', input, HEADER)
        .then(response => {
          dispatch(getMemberCourseListSuccess(response));
        })
        .catch(error => {
          dispatch(getMemberCourseListError("Save error."))
        });
    }
  }
}

export function getMemberTechnicList(state: { state: { memberId: any; }; }) {
  const id = state.state.memberId;
  return (dispatch: (arg0: memberActions) => void) => {
    if (id != null) {
      const input = { memberId: id }
      axios.post(SERVICEURL + 'api/Personnel/GetTechnicList?', input, HEADER)
        .then(response => {
          dispatch(getMemberTechnicListSuccess(response));
        })
        .catch(error => {
          dispatch(getMemberTechnicListError("Save error."))
        });
    }
  }
}

export function getPersonalAbilityList() {
  return (dispatch: (arg0: memberActions) => void) => {
    axios.get(SERVICEURL + 'api/Data/GetPersonalAbilityList?', HEADER)
      .then(response => {

        dispatch(getPersonalAbilitiyListSuccess(response));
      })
      .catch(error => {

        console.log(error)

        dispatch(getPersonalAbilitiyListError("Save error."))
      });
  }
}

export function getMemberCollageList(state: { state: { memberId: any; }; }) {
  const id = state.state.memberId;
  return (dispatch: (arg0: memberActions) => void) => {

    if (id != null) {
      const input = { MemberID: id }
      axios.post(SERVICEURL + 'api/Personnel/GetEducationList?', input, HEADER)
        .then(response => {
          dispatch(getMemberCollageListSuccess(response));
        })
        .catch(error => {
          dispatch(getMemberCollageListError("Save error."))
        });
    }
  }
}

export function getMemberGoodPersonalAbilityList(state: { state: { memberId: any; }; }) {
  const id = state.state.memberId;
  return (dispatch: (arg0: memberActions) => void) => {
    if (id != null) {
      const input = { MemberId: id }
      axios.post(SERVICEURL + 'api/Personnel/GetPersonalGoodAbilityList?', input, HEADER)
        .then(response => {
          dispatch(getMemberGoodPersonalAbilitiyListSuccess(response));
        })
        .catch(error => {
          dispatch(getMemberGoodPersonalAbilitiyListError("Save error."))
        });
    }
  }
}

export function getMemberBadPersonalAbilityList(state: { state: { memberId: any; }; }) {
  const id = state.state.memberId;
  return (dispatch: (arg0: memberActions) => void) => {
    if (id != null) {
      const input = { MemberId: id }
      axios.post(SERVICEURL + 'api/Personnel/GetPersonalBadAbilityList?', input, HEADER)
        .then(response => {
          dispatch(getMemberBadPersonalAbilitiyListSuccess(response));
        })
        .catch(error => {
          dispatch(getMemberBadPersonalAbilitiyListError("Save error."))
        });
    }
  }
}

export function getMemberLanguageList(state: { state: { memberId: any; }; }) {
  const id = state.state.memberId;
  return (dispatch: (arg0: memberActions) => void) => {

    if (id != null) {
      const input = { MemberId: id }
      axios.post(SERVICEURL + 'api/Personnel/GetLanguageList?', input, HEADER)
        .then(response => {
          dispatch(getMemberLanguageListSuccess(response));
        })
        .catch(error => {
          dispatch(getMemberLanguageListError("Save error."))
        });
    }
  }
}
// export function getPostionList(state: { technicGroupFilterId: number; techvalue: number[]; CityIds: number[];  state: { priority: number; workingModelId: number; projectTypeId: number; companyId: number; customerId: number; };  stateWorkingModel: { Remote: boolean; Hybrid: boolean; Onsite: boolean }; stateProjectType: { Insource: boolean; Outsource: boolean } }) {
//   //const technicGroupId = state.state.technicGroupId;
//   const priority = state.state.priority;
//   const workingModelId = state.state.workingModelId;
//   const projectTypeId = state.state.projectTypeId;
//   const techGroup = state.techvalue;
//   const remote = state.stateWorkingModel.Remote;
//   const hybrid = state.stateWorkingModel.Hybrid;
//   const onsite = state.stateWorkingModel.Onsite;
//   const insource = state.stateProjectType.Insource;
//   const outsource = state.stateProjectType.Outsource;
//   const companyId = state.state.companyId;
//   const customerId = state.state.customerId;
//   const technicGroupFilterId = state.technicGroupFilterId;
//   const city = state.CityIds;



 
//   return (dispatch: (arg0: memberActions) => void) => {

//     if ((techGroup != null) || (priority != null) || (projectTypeId != null) || (workingModelId != null)) {
//       const input = { TechnicGroupFilterId: technicGroupFilterId, techGroupId: techGroup, CityID: city, WorkingModel: workingModelId,  CompanyId: companyId, CustomerId: customerId, Priority: priority, ProjectType: projectTypeId,  IsRemote: remote, IsHybrit: hybrid, IsOnsite: onsite, IsInsource: insource, IsOutsource: outsource }
 //       axios.post(SERVICEURL + 'api/Position/PositionList', input, HEADER)
//         .then(response => {
//           dispatch(getPositionListSuccess(response));
//         })
//         .catch(error => {
//           dispatch(getPositionListError("Save error."))
//           localStorage.removeItem('token');
//           window.location.reload();
//         });
//     }

//   }
// }


export function getPostionList(state: { technicGroupFilterId: number; techvalue: number[]; ids: number; stateCity: { Anadolu: boolean; Avrupa: boolean; Izmir: boolean; Ankara: boolean; Diger: boolean }; state: { priority: number; workingModelId: number; projectTypeId: number; companyId: number; customerId: number; }; stateWorkingModel: { Remote: boolean; Hybrid: boolean; Onsite: boolean }; stateProjectType: { Insource: boolean; Outsource: boolean } }) {
  //const technicGroupId = state.state.technicGroupId;
  const priority = state.state.priority;
  const workingModelId = state.state.workingModelId;
  const projectTypeId = state.state.projectTypeId;
  const techGroup = state.techvalue;
  const remote = state.stateWorkingModel.Remote;
  const hybrid = state.stateWorkingModel.Hybrid;
  const onsite = state.stateWorkingModel.Onsite;
  const insource = state.stateProjectType.Insource;
  const outsource = state.stateProjectType.Outsource;
  const companyId = state.state.companyId;
  const customerId = state.state.customerId;
  const technicGroupFilterId = state.technicGroupFilterId;
  const stateCity = state.stateCity;
  const ids = state.ids;

  var CityIds: number[] = [];
  let cityIds = [...CityIds];
  if (stateCity.Anadolu == true) {
    CityIds.push(34);

  }
  if (stateCity.Anadolu == false) {
    var index = CityIds.indexOf(34);
    if (index !== -1) {
      CityIds.splice(index, 1);
    }
  }
  if (stateCity.Avrupa == true) {
    CityIds.push(88);
  }
  if (stateCity.Avrupa == false) {
    var index = CityIds.indexOf(88);
    if (index !== -1) {
      CityIds.splice(index, 1);
    }
  }
  if (stateCity.Ankara == true) {
    CityIds.push(6);
  }
  if (stateCity.Ankara == false) {
    var index = CityIds.indexOf(6);
    if (index !== -1) {
      CityIds.splice(index, 1);
    }
  }
  if (stateCity.Izmir == true) {
    CityIds.push(35);
  }
  if (stateCity.Izmir == false) {
    var index = CityIds.indexOf(35);
    if (index !== -1) {
      CityIds.splice(index, 1);
    }
  }

  if (stateCity.Diger == true) {
    if (ids != 0) {
      CityIds.push(ids);
    } else {
      var index = CityIds.indexOf(0);
      if (index !== -1) {
        CityIds.splice(index, 1);
      }
    }
  }
  if (stateCity.Diger == false) {
    if (ids != 0) {
      var index = CityIds.indexOf(ids);
      if (index !== -1) {
        CityIds.splice(index, 1);
      }
    } else {
      var index = CityIds.indexOf(0);
      if (index !== -1) {
        CityIds.splice(index, 1);
      }
    }
  }

  return (dispatch: (arg0: memberActions) => void) => {

    if ((techGroup != null) || (priority != null) || (projectTypeId != null) || (workingModelId != null)) {
      const input = { TechnicGroupFilterId: technicGroupFilterId, techGroupId: techGroup, CityID: CityIds, WorkingModel: workingModelId, CompanyId: companyId, CustomerId: customerId, Priority: priority, ProjectType: projectTypeId, IsRemote: remote, IsHybrit: hybrid, IsOnsite: onsite, IsInsource: insource, IsOutsource: outsource }
       axios.post(SERVICEURL + 'api/Position/PositionList', input, HEADER)
        .then(response => {
          dispatch(getPositionListSuccess(response));
        })
        .catch(error => {
          dispatch(getPositionListError("Save error."))
          localStorage.removeItem('token');
          window.location.reload();
        });
    }

  }
}
export function getPositionListByCompany(state: { companyName: string; customerName: string; ids: number; stateCity: { Anadolu: boolean; Avrupa: boolean; Izmir: boolean; Ankara: boolean; Diger: boolean }; techvalue: number[]; state: { priority: number; } }) {
  const companyName = state.companyName;
  const customerName = state.customerName;
  const techGroup = state.techvalue;
  //const cityID = state.cityID;
  //const technicGroupId = state.techvalue;
  const priority = state.state.priority;
  const stateCity = state.stateCity;
  const ids = state.ids;

  var CityIds: number[] = [];
  let cityIds = [...CityIds];
  if (stateCity.Anadolu == true) {
    CityIds.push(34);

  }
  if (stateCity.Anadolu == false) {
    var index = CityIds.indexOf(34);
    if (index !== -1) {
      CityIds.splice(index, 1);
    }
  }
  if (stateCity.Avrupa == true) {
    CityIds.push(88);
  }
  if (stateCity.Avrupa == false) {
    var index = CityIds.indexOf(88);
    if (index !== -1) {
      CityIds.splice(index, 1);
    }
  }
  if (stateCity.Ankara == true) {
    CityIds.push(6);
  }
  if (stateCity.Ankara == false) {
    var index = CityIds.indexOf(6);
    if (index !== -1) {
      CityIds.splice(index, 1);
    }
  }
  if (stateCity.Izmir == true) {
    CityIds.push(35);
  }
  if (stateCity.Izmir == false) {
    var index = CityIds.indexOf(35);
    if (index !== -1) {
      CityIds.splice(index, 1);
    }
  }

  if (stateCity.Diger == true) {
    if (ids != 0) {
      CityIds.push(ids);
    } else {
      var index = CityIds.indexOf(0);
      if (index !== -1) {
        CityIds.splice(index, 1);
      }
    }
  }
  if (stateCity.Diger == false) {
    if (ids != 0) {
      var index = CityIds.indexOf(ids);
      if (index !== -1) {
        CityIds.splice(index, 1);
      }
    } else {
      var index = CityIds.indexOf(0);
      if (index !== -1) {
        CityIds.splice(index, 1);
      }
    }
  }
  return (dispatch: (arg0: memberActions) => void) => {

    if (companyName != null || companyName != '') {
      const input = { CompanyName: companyName, CustomerName: customerName, CityID: CityIds, techGroupId: techGroup, PriorityId: priority }
       axios.post(SERVICEURL + 'api/Position/PositionListingByCompany', input, HEADER)
        .then(response => {
          dispatch(getPositionListByCompanySuccess(response));
        })
        .catch(error => {
          dispatch(getPositionListByCompanyError("Save error."))
          localStorage.removeItem('token');
          window.location.reload();
        });
    }

  }
}

export function getPositionListByProject(state: { projectId: number }) {
  const projectId = state.projectId;
 
  return (dispatch: (arg0: memberActions) => void) => {

    if (projectId != null || projectId != 0) {
      const input = { ProjectId: projectId }
      axios.post(SERVICEURL + 'api/Position/PositionListingByProject', input, HEADER)
        .then(response => {
          dispatch(getPositionListByProjectSuccess(response));
        })
        .catch(error => {
          dispatch(getPositionListByProjectError("Save error."))
        });
    }

  }
}

export function getCustomerList() {
  return (dispatch: (arg0: memberActions) => void) => {

    axios.get(SERVICEURL + 'api/Data/GetCustomerList', HEADER)
      .then(response => {
        dispatch(getCustomerListSuccess(response));
      })
      .catch(error => {
        dispatch(getCustomerListError("Save error."))
      });

  }
}

export function getMemberDomainList(state: { state: { memberId: any; }; }) {
  const id = state.state.memberId;
  return (dispatch: (arg0: memberActions) => void) => {
    if (id != null) {
      const input = { memberId: id }
      axios.post(SERVICEURL + 'api/Personnel/GetDomainList?', input, HEADER)
        .then(response => {
          dispatch(getMemberDomainListSuccess(response));
        })
        .catch(error => {
          dispatch(getMemberDomainListError("Save error."))
        });
    }
  }
}

export function getAllSubStatusList() {

  return (dispatch: (arg0: memberActions) => void) => {


    axios.get(SERVICEURL + 'api/Data/GetAllSubStatusList?', HEADER)
      .then(response => {
        dispatch(getAllSubStatusListSuccess(response));
      })
      .catch(error => {
        dispatch(getAllSubStatusListError("Save error."))
      });

  }
}

export function getInterviwerList() {

  return (dispatch: (arg0: memberActions) => void) => {


    axios.get(SERVICEURL + 'api/Data/GetInterviewerList?', HEADER)
      .then(response => {
        dispatch(getAllInterviwerListSuccess(response));
      })
      .catch((reason: AxiosError) => {
        if (reason.response!.status === 401) {
          localStorage.removeItem('token');
          window.location.reload();
        } else {
          dispatch(getAllInterviwerListError("Save error."))
        }

      })

  }
}
export function getMemberTechnicDetailList(state: { memberId: number; }) {

  const id = state.memberId;
  return (dispatch: (arg0: memberActions) => void) => {
    if (id != null) {
      const input = { memberId: id }
      axios.post(SERVICEURL + 'api/Report/GetMemberTechnicalDetail?', input, HEADER)
        .then(response => {
          dispatch(getMemberTechnicDetailSuccess(response));
        })
        .catch(error => {
          dispatch(getMemberTechnicDetailError("Save error."))
        });
    }
  }
}


export function getPositionListByTechnicGroup(state: { techvalue: number[]; ids: number; stateCity: { Anadolu: boolean; Avrupa: boolean; Izmir: boolean; Ankara: boolean; Diger: boolean }; state: { priority: number; workingModelId: number; projectTypeId: number; companyId: number; customerId: number; }; stateWorkingModel: { Remote: boolean; Hybrid: boolean; Onsite: boolean }; stateProjectType: { Insource: boolean; Outsource: boolean } }) {
  const priority = state.state.priority;
  const techGroup = state.techvalue;
  const workingModelId = state.state.workingModelId;
  const projectTypeId = state.state.projectTypeId;
  const remote = state.stateWorkingModel.Remote;
  const hybrid = state.stateWorkingModel.Hybrid;
  const onsite = state.stateWorkingModel.Onsite;
  const insource = state.stateProjectType.Insource;
  const outsource = state.stateProjectType.Outsource;
  const companyId = state.state.companyId;
  const customerId = state.state.customerId;
  const stateCity = state.stateCity;
  const ids = state.ids;

  var CityIds: number[] = [];
  let cityIds = [...CityIds];
  if (stateCity.Anadolu == true) {
    CityIds.push(34);

  }
  if (stateCity.Anadolu == false) {
    var index = CityIds.indexOf(34);
    if (index !== -1) {
      CityIds.splice(index, 1);
    }
  }
  if (stateCity.Avrupa == true) {
    CityIds.push(88);
  }
  if (stateCity.Avrupa == false) {
    var index = CityIds.indexOf(88);
    if (index !== -1) {
      CityIds.splice(index, 1);
    }
  }
  if (stateCity.Ankara == true) {
    CityIds.push(6);
  }
  if (stateCity.Ankara == false) {
    var index = CityIds.indexOf(6);
    if (index !== -1) {
      CityIds.splice(index, 1);
    }
  }
  if (stateCity.Izmir == true) {
    CityIds.push(35);
  }
  if (stateCity.Izmir == false) {
    var index = CityIds.indexOf(35);
    if (index !== -1) {
      CityIds.splice(index, 1);
    }
  }

  if (stateCity.Diger == true) {
    if (ids != 0) {
      CityIds.push(ids);
    } else {
      var index = CityIds.indexOf(0);
      if (index !== -1) {
        CityIds.splice(index, 1);
      }
    }
  }
  if (stateCity.Diger == false) {
    if (ids != 0) {
      var index = CityIds.indexOf(ids);
      if (index !== -1) {
        CityIds.splice(index, 1);
      }
    } else {
      var index = CityIds.indexOf(0);
      if (index !== -1) {
        CityIds.splice(index, 1);
      }
    }
  }

  //const technicGroupId = state.state.technicGroupId;



  return (dispatch: (arg0: memberActions) => void) => {

    if ((priority != null) || (projectTypeId != null) || (workingModelId != null)) {
      const input = { WorkingModel: workingModelId, techGroupId: techGroup, CompanyId: companyId, CityID: CityIds, CustomerId: customerId, Priority: priority, ProjectType: projectTypeId, IsRemote: remote, IsHybrit: hybrid, IsOnsite: onsite, IsInsource: insource, IsOutsource: outsource }
       axios.post(SERVICEURL + 'api/Position/PositionListTechnic', input, HEADER)
        .then(response => {
          dispatch(getPositionListTechnicGroupSuccess(response));
        })
        .catch(error => {
          dispatch(getPositionListTechnicGroupError("Save error."))

        });
    }

  }
}

export function getTechnicGroupNumberOfPeopleList(state: { subStatusIds: never[]; techvalue: number[]; state: { dateStart: Date | null; dateEnd: Date | null; companyName: string; customerId: number; }; }) {
  const dateStart = state.state.dateStart;
  const dateEnd = state.state.dateEnd;
  const companyName = state.state.companyName;
  const techGroup = state.techvalue;
  const customerId = state.state.customerId;
  const subStatusId = state.subStatusIds[0] != undefined ? state.subStatusIds : null;

  return (dispatch: (arg0: memberActions) => void) => {

    if (dateStart != null && dateStart.getFullYear() != 1970 && dateEnd != null && dateEnd.getFullYear() != 1970) {
      const input = { StartDate: dateStart, EndDate: dateEnd, CompanyName: companyName, TechId: techGroup, CustomerId: customerId, SubStatusIds: subStatusId }
      axios.post(SERVICEURL + 'api/Report/ListTechnicalGroupReportNumber?', input, HEADER)
        .then(response => {
           dispatch(getTechnicGroupNumberOfPeopleSuccess(response));
        })
        .catch(error => {
          dispatch(getTechnicGroupNumberOfPeopleError("Save error."))
        });
    }
  }
}

export function getTechnicGroupReportList(state: { subStatusIds: never[]; techvalue: number[]; state: { dateStart: Date | null; dateEnd: Date | null; companyName: string; customerId: number; }; }) {
  const dateStart = state.state.dateStart;
  const dateEnd = state.state.dateEnd;
  const companyName = state.state.companyName;
  const techGroup = state.techvalue;
  const customerId = state.state.customerId;
  const subStatusId = state.subStatusIds[0] != undefined ? state.subStatusIds : null;

  return (dispatch: (arg0: memberActions) => void) => {

    if (dateStart != null && dateStart.getFullYear() != 1970 && dateEnd != null && dateEnd.getFullYear() != 1970) {
      const input = { StartDate: dateStart, EndDate: dateEnd, CompanyName: companyName, TechId: techGroup, CustomerId: customerId, SubStatusIds: subStatusId }
      axios.post(SERVICEURL + 'api/Report/ListTechnicalGroupReport?', input, HEADER)
        .then(response => {
           dispatch(getTechnicGroupReportSuccess(response));
        })
        .catch(error => {
          dispatch(getTechnicGroupReportError("Save error."))
        });
    }
  }
}

export function getTechnicalSelectTreeList() {

  return (dispatch: (arg0: memberActions) => void) => {

    axios.get(SERVICEURL + 'api/Data/GetTechnicalSelectTreeList?', HEADER)
      .then(response => {
        dispatch(getTechnicalSelectTreeListSuccess(response));
      })
      .catch(error => {
        dispatch(getTechnicalSelectTreeListError("Save error."))
      });
  }
}

export function getMainTechnologiesList() {

  return (dispatch: (arg0: memberActions) => void) => {

    axios.get(SERVICEURL + 'api/Data/GetMainTechnologiesList?', HEADER)
      .then(response => {
        dispatch(getMainTechnologiesListSuccess(response));
      })
      .catch(error => {
        dispatch(getMainTechnologiesListError("Save error."))
      });
  }
}

export function getSubTechnologiesList() {

  return (dispatch: (arg0: memberActions) => void) => {

    axios.get(SERVICEURL + 'api/Data/GetSubTechnologiesList?', HEADER)
      .then(response => {
        dispatch(getSubTechnologiesListSuccess(response));
      })
      .catch(error => {
        dispatch(getSubTechnologiesListError("Save error."))
      });
  }
}

export function getDomainList() {

  return (dispatch: (arg0: memberActions) => void) => {

    axios.get(SERVICEURL + 'api/Data/GetDomainList?', HEADER)
      .then(response => {
        dispatch(getDomainListSuccess(response));
      })
      .catch(error => {
        dispatch(getDomainListError("Save error."))
      });
  }
}
//
export function getLanguageList() {

  return (dispatch: (arg0: memberActions) => void) => {

    axios.get(SERVICEURL + 'api/Data/GetLanguageList?', HEADER)
      .then(response => {
        dispatch(getLanguageListSuccess(response));
      })
      .catch(error => {
        dispatch(getLanguageListError("Save error."))
      });
  }
}
export function getLanguageLevelList() {

  return (dispatch: (arg0: memberActions) => void) => {

    axios.get(SERVICEURL + 'api/Data/GetLevelList?', HEADER)
      .then(response => {
        dispatch(getLanguageLevelListSuccess(response));
      })
      .catch(error => {
        dispatch(getLanguageLevelListError("Save error."))
      });
  }
}
//

export function getPersonalCommunicationLevelList() {

  return (dispatch: (arg0: memberActions) => void) => {

    axios.get(SERVICEURL + 'api/Data/GetPersonalCommunicationLevelList?', HEADER)
      .then(response => {
        dispatch(getPersonalCommunicationLevelListSuccess(response));
      })
      .catch(error => {
        dispatch(getPersonalCommunicationLevelListError("Save error."))
      });
  }
}

export function getMemberPersonalSkillList(state: { state: { memberId: any; }; }) {
  const id = state.state.memberId;
  return (dispatch: (arg0: memberActions) => void) => {
    if (id != null) {
      const input = { memberId: id }
      axios.post(SERVICEURL + 'api/Personnel/GetMemberPersonalSkillList?', input, HEADER)
        .then(response => {
          dispatch(getMemberPersonalSkillListSuccess(response));
        })
        .catch(error => {
          dispatch(getMemberPersonalSkillListError("Save error."))
        });
    }
  }
}

export function getSubTechnologiesListByMainGroup(state: { state: { mainTechnicalId: any; }; }) {
  const id = state.state.mainTechnicalId.toString();
  return (dispatch: (arg0: memberActions) => void) => {
    if (id != null) {
      const input = { Id: id }
      axios.post(SERVICEURL + 'api/Data/GetSubTechnologiesListbyMainGroup?', input, HEADER)
        .then(response => {
          dispatch(getSubTechnologiesListByMainGroupSuccess(response));
        })
        .catch(error => {
          dispatch(getSubTechnologiesListByMainGroupError("Save error."))
        });
    }
  }
}

export function getTechnicGroupMainList() {

  return (dispatch: (arg0: memberActions) => void) => {

    axios.get(SERVICEURL + 'api/Data/GetTechnicalMainGroupList', HEADER)
      .then(response => {
        dispatch(getTechnicGroupMainListSuccess(response));
      })
      .catch(error => {
        dispatch(getTechnicGroupMainListError("Save error."))
      });
  }
}

export function getMemberTechnicGroupList(state: { state: { memberId: any; }; }) {
  const id = state.state.memberId;
  return (dispatch: (arg0: memberActions) => void) => {
    if (id != null) {
      const input = { memberId: id }
      axios.post(SERVICEURL + 'api/Personnel/GetTechnicGroupList?', input, HEADER)
        .then(response => {
          dispatch(getMemberTechnicGroupListSuccess(response));
        })
        .catch(error => {
          dispatch(getMemberTechnicGroupListError("Save error."))
        });
    }
  }
}

export function getStatusStatisticList(state: { state: { dateStart: Date | null; dateEnd: Date | null; statusName: string; ikPersonnelId: number; }; }) {
  const dateStart = state.state.dateStart;
  const dateEnd = state.state.dateEnd;
  const ikPersonnelId = state.state.ikPersonnelId;
  const statusName = state.state.statusName;

  return (dispatch: (arg0: memberActions) => void) => {

    if (dateStart != null && dateStart.getFullYear() != 1970 && dateEnd != null && dateEnd.getFullYear() != 1970) {
      const input = { StartDate: dateStart, EndDate: dateEnd, StatusName: statusName, IkPersonnelId: ikPersonnelId }
       axios.post(SERVICEURL + 'api/StatusStaticReport/ListStatusStatic?', input, HEADER)
        .then(response => {
           dispatch(getStatusStatisticListSuccess(response));
        })
        .catch(error => {
          dispatch(getStatusStatisticListError("Save error."))
        });
    }
  }
}

export function getStatusStatisticbyMemberList(state: { state: { dateStart: Date | null; dateEnd: Date | null; statusName: string; ikPersonnelId: number; }; }) {
  const dateStart = state.state.dateStart;
  const dateEnd = state.state.dateEnd;
  const ikPersonnelId = state.state.ikPersonnelId;
  const statusName = state.state.statusName;

  return (dispatch: (arg0: memberActions) => void) => {

    if (dateStart != null && dateStart.getFullYear() != 1970 && dateEnd != null && dateEnd.getFullYear() != 1970) {
      const input = { StartDate: dateStart, EndDate: dateEnd, StatusName: statusName, IkPersonnelId: ikPersonnelId }
       axios.post(SERVICEURL + 'api/StatusStaticReport/GetStatusStaticByMember?', input, HEADER)
        .then(response => {
           dispatch(getStatusStatisticbyMemberListSuccess(response));
        })
        .catch(error => {
          dispatch(getStatusStatisticbyMemberListError("Save error."))
        });
    }
  }
}


export function getAllMemberListForPosition(state: { technicId: number; value: number[]; techvalue: number[]; domainValue: number[]; state: { englishLevelId: number; experienceYearEnd: number; experienceYearStart: number; salaryEndValue: number; salaryFirstValue: number; features: number[]; languageId: number; languageLevelId: number; dateStart: Date | null; dateEnd: Date | null }; }) {
  const techGroup = state.techvalue;
  const englishLevelId = state.state.englishLevelId;
  const experienceYearEnd = state.state.experienceYearEnd;
  const experienceYearStart = state.state.experienceYearStart;
  const salaryEndValue = state.state.salaryEndValue;
  const salaryFirstValue = state.state.salaryFirstValue;
  const technicId = state.technicId;
  const features = state.value;
  const domainId = state.domainValue;
  const languageId = state.state.languageId;
  const languageLevelId = state.state.languageLevelId;
  const dateStart = state.state.dateStart;
  const dateEnd = state.state.dateEnd;

  const Language = {
    languageParameterId: languageId,
    levelId: languageLevelId
  };

  return (dispatch: (arg0: memberActions) => void) => {

    if (techGroup != null || englishLevelId != null || experienceYearEnd != null || experienceYearStart != null || features != null || languageId != null || languageLevelId != null || domainId != null || dateStart != null || dateEnd != null) {
      const input = { techGroupId: techGroup, languageId: englishLevelId, endingTotalExperienceYear: experienceYearEnd, startingTotalExperienceYear: experienceYearStart, endingSalaryExpectation: salaryEndValue, startingSalaryExpectation: salaryFirstValue, technicalSkillId: technicId, features: features, otherlanguageId: Language, domainId: domainId, StartDate: dateStart, EndDate: dateEnd }
       axios.post(SERVICEURL + 'api/Membership/GetAllMemberListForPosition', input, HEADER)
        .then(response => {
          dispatch(getAllMemberListForPositionSuccess(response));
        })
        .catch(error => {
          dispatch(getAllMemberListForPositionError("Save error."))
        });
    }
  }
}

export function getDomainMainGroupList() {

  return (dispatch: (arg0: memberActions) => void) => {

    axios.get(SERVICEURL + 'api/Data/GetDomainMainList?', HEADER)
      .then(response => {
        dispatch(getDomainMainGroupListSuccess(response));
      })
      .catch(error => {
        dispatch(getDomainMainGroupListError("Save error."))
      });
  }
}

export function getDomainSubGroupList() {

  return (dispatch: (arg0: memberActions) => void) => {

    axios.get(SERVICEURL + 'api/Data/GetDomainSubList?', HEADER)
      .then(response => {
        dispatch(getDomainSubGroupListSuccess(response));
      })
      .catch(error => {
        dispatch(getDomainSubGroupListError("Save error."))
      });
  }
}